@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap");

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.patient-detail-container {
  // width: 1313px;
  // height: 360px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.patient-detail-container-patientInfo {
  // width: 1250px;
  // height: 220px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.patient-detail-topRow {
  border-bottom: 1px solid #e9e9e9;
  padding: 1rem;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
}
.patient-detail-topRow-profile {
  display: flex;
  flex-direction: row;
}
.patient-name {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: -1px;
  justify-content: center;
}
.hoverIcon {
  visibility: hidden;
}
.subheading {
  color: #222;
  font-family: "Mulish", sans-serif;
  font-size: 20px;
  font-weight: 900;
  margin-left: 10px;
  // padding-left: 4px;
  // cursor: pointer;
  // &:hover {
  //   color: #05a2bd;
  // }
  &.subheading-smilealign {
    &:hover {
      color: #de8754;
    }
  }
}

.profile-icon {
  border: 2px solid #e9e9e9;
  height: 60px;
  width: 60px;
  border-radius: 35px;
}
.user-icon-image {
  height: 30px;
  width: 30px;
  margin-top: 12px;
  margin-left: 1px;
  color: #05a2bd !important;
}
.patient-detail-topRow-switcher {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  .ant-radio-group{
    display: flex;
  }
  .ant-radio-button-wrapper{
    min-width: 180px !important;
    text-align: center !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
}
.ant-radio-button-wrapper:first-child {
  border-radius: 40px 0 0 40px !important;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 40px 40px 0 !important;
}
.patient-detail-bottomRow {
   display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 1rem;
  padding: 1rem;
}
.patient-detail-infoRow{
  display: block;
}

.patient-detail-bottomRow-itemOne {
  background-color: #fafafa;
  // width: 839px;
  // width: 905px;
  border-radius: 14px;
  border: 2px dashed #e9e9e9;
}

.patient-detail-bottomRow-itemTwo {
  background-color: #fafafa;
  border-radius: 14px;
  border: 2px dashed #e9e9e9;
  // margin-left: 65px;
}
.status-container {
  height: 80%;
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  .edit-icon {
    position: absolute;
    right: 20px;
    top: 5px;
  }
  .patient-detail-heading-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 0.7rem;
  }

  .patient-info-cells {
    display: flex;
    flex-direction: column;
    border-right: 2px solid #e9e9e9;
    width: 25%;
    gap: 4px;
  }

  .status-heading {
    text-align: left;
    font-weight: 700;
  }
  .patient-tag {
    padding: 8px 12px;
    margin-top: 9px;
    border-radius: 25px !important;
    color: #ddfaff;
    // .ant-tag {
    //   padding: 8px 12px ;
    //   margin-top: 9px;
    //   border-radius: 25px;
    // }
  }
}
.ant-dropdown-link {
  color: #222222;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
}
.aligner {
  // color: #05a2bd;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 14px;
  // background: #ddfaff;
  padding: 0.6rem 1rem;
  border-radius: 2rem;
  width: auto;
  text-align: center;
  &.boost-aligner {
    border-radius: 2rem;
    padding: 6px 10px;
  }
}
.aligner-change-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
  .aligner {
    // color: #05a2bd;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 13px;
    // background: #ddfaff;
    padding: 3px 12px;
    border-radius: 10px;
    width: auto;
    text-align: center;
    margin-top: 10px;
  }
  .date {
    font-size: 12px;
  }
  .date-passed {
    font-size: 12px;
    color: #f205e2;
  }
}
.patient-info {
  background-color: #fafafa;
  width: 98%;
  margin-bottom: 32px;
  border-radius: 13px;
  border: 2px dashed #e9e9e9;
  min-height: 80px;
  padding: 10px;
  svg {
    // width: 50px;
    // height: 20px;
  }
}

.hidden {
  display: none;
}

.caption {
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  color: #798c96;
  font-size: 12px;
  text-align: left;
  // padding-left: 10px;
}
.treatment-date {
  text-align: left;
  padding-left: 10px;
  padding-top: 5px;
  font-size: 12px;
  .label {
    color: #798c96;
  }
}
.create-date {
  text-align: left;
  padding-left: 0px;
  padding-top: 5px;
  font-size: 12px;
  .label {
    color: #798c96;
    font-weight: 500;
  }
  b {
    display: block;
  }
}

.content {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  color: #222222;
  // margin-top: 4px;
  text-align: left;
  padding-left: 10px;
  padding-right: 5px;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  hyphens: auto;
  // width: 320px;
  white-space: nowrap;
  white-space: initial;

  .ant-select {
    width: 100% !important;
    text-align: left !important;
  }
}
.gender{
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px;
  width: 170px;
  text-align: left;
  span{
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    color: #222222;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0px;
    width: 170px;
  }
}

.main-container {
  width: 70.5%;
}

.site-page-header {
  text-align: left;
  padding-right: 0 !important;
}

.editIcon {
  visibility: hidden;
}

.editIconHover {
  &:hover {
    .editIcon {
      visibility: visible;
      cursor: pointer;
    }
  }
}
.assign-clinician {
  .flag-btn {
    font-weight: 500;
    border-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    .ant-btn-icon {
      margin: 0px !important;
    }
    svg {
      margin-left: 5px;
    }
  }
  .send-appBtn {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    .ant-btn-icon {
      margin: 0px !important;
    }
    svg {
      margin-left: 5px;
    }
  }
  display: flex;
  align-items: center;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: #798c96;
  font-size: 12px;
  text-align: left;
  .clinician-name {
    cursor: pointer;
  }
  .assign-clinician-hover {
    &:hover {
      .editIcon {
        visibility: visible;
      }
      // .clinician-name {
      //   color: #05a2bd;
      // }
    }
  }
}
.ant-modal-body {
  border-radius: 20px;
  // .ant-modal-confirm-body-wrapper{
  //   height: 150px;
  // }
}
.appTable-column-title{
// white-space: nowrap;
// overflow: hidden;
// text-overflow: ellipsis;
margin: 0px;
display: -webkit-box;
max-width: 200px;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
overflow: hidden;
font-weight: bold;
}
.patient-name-tableData{
  p{
    margin: 0px;
    
  }
}
@media only screen and (min-width: 1440px) {
  .patient-list-table {
    table {
      th:last-child {
        padding-right: 30px;
      }
    }
  }
}

.selected-treatment-tick {
  background-color: #e4f5eb;
  border-radius: 50%;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-right: -15px;
  width: 40px;
  height: 40px;

  .anticon {
    font-size: 18px;
    color: green;
  }
}

.treatment-selectbox-multi {
  .ant-select-item-option-content {
    display: flex;
    justify-content: space-between;
    .anticon {
      font-size: 18px;
      color: green;
    }
  }
}

.approve-sent-patient-btn {
  width: unset !important;
}

.treatment-dropdown-container {
  // display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1540px) {
  .treatment-actions-buttons {
    margin-top: 10px;
    .selected-treatment-tick {
      &.rejected {
        margin-top: 10px;
      }
    }
  }
}

.netPrice-dropdown {
  width: 280px !important;
  padding: 0 !important;
}

.price-dropDown {
  p {
    margin: 0;
  }
  .ant-input {
    height: 35px !important;
  }
  .row-one {
    display: grid;
    grid-template-columns: 1fr 3fr;
    margin: 10px 0;
    gap: 5px;
    text-align: left;
    align-items: center;
    padding: 0px 6px;
  }
  .row-two {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 5px;
  }

  // . {
  //   width: 100%;
  //   margin: 0 10px;
  // }

  .price-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 800;
    padding: 0px 5px;
    color: #000;
  }
}

.discount-amount-input {
  width: 100%;
  .ant-input-number-input-wrap,
  input {
    height: 100% !important;
  }
}

.treatment-comment-container {
  display: flex;
  flex-direction: column;

  .treatment-comment-editor-text {
    margin-top: 15px;
    border: 2px dashed #e7e7e7;
    background-color: #fafafa;
    border-radius: 15px;
    padding: 10px;
    min-height: 150px;
    margin-bottom: 100px;
  }
  .edit-btn {
    color: #fff;
    padding: 4px 15px;
    margin-left: auto;
    width: 6rem;
    height: 32px;
  }
}

.ql-editor {
  &::before {
    font-style: normal !important;
  }
}

.patient-details-container{
  position: relative;

  .patient-header-row{
    padding: 1rem;
    // min-height: 5.5rem;
    margin-left: 1rem;
    // margin-bottom: 1rem;
  }
}

.treatment-progress {
  // display: flex;
  // justify-content: space-between;
  font-family: 'mulish', sans-serif;
  text-align: left;

  &__left {
    flex: 1;
    display: flex;
    background-color: #fafafa;
    border-radius: 8px;
    border: 1px dashed #e0e0e0;
    padding: 20px;
  }

  &__right {
    // width: 400px;
    background-color: #fafafa;
    border-radius: 8px;
    border: 1px dashed #e0e0e0;
    padding: 20px;
    // margin-left: 20px;
    height: 100%;
  }

  &__section {
    flex: 1;
    padding: 0 20px;
    
    &:not(:last-child) {
      border-right: 1px solid #e0e0e0;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__heading {
    font-size: 16px;
    font-weight: 700;
    color: #333333;
    margin: 0 0 12px 0;
  }

  &__aligner-badge {
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
  }

  &__info-group {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__label {
    display: block;
    font-size: 14px;
    color: #666666;
    margin-bottom: 4px;
    font-weight: 500;
  }

  &__value {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
  }
}

.therapy-tracker {
  // display: flex;
  // background-color: #fafafa;
  // border: 1px dashed #e0e0e0;
  border-radius: 8px;
  font-family: 'mulish', sans-serif;
  padding: 24px;
  gap: 24px; // Add space between containers

  &__section {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fafafa; // Add background color to each section
    border: 1px dashed #e0e0e0;
    border-radius: 8px;

    &--progress,
    &--visible {
      flex-direction: row;
      height: 100%;
    }

    &--status {
      // max-width: 250px;
    }
  }

  &__column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &:first-child {
      margin-right: 10px; // Add space between columns
    }
  }

  &__heading {
    font-size: 16px;
    font-weight: 700;
    color: #333;
    margin: 0 0 16px;
    text-align: left;
  }

  &__aligner {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
    width: 150px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__label {
    font-size: 14px;
    color: #666;
    text-align: left;
    font-weight: 500;
  }

  &__value {
    font-size: 14px;
    color: #333;
    font-weight: 600;
    text-align: left;
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__edit-icon,
  &__user-icon {
    width: 16px;
    height: 16px;
    color: #79d1e3;
  }

  &__edit-icon {
    cursor: pointer;
  }

  &__patient-status {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}


.price-editor {
  position: relative;
  font-family: 'mulish', sans-serif;
  text-align: left;

  &__display {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__current-price {
    font-size: 1.125rem;
    font-weight: 600;
    color: #333;
  }

  &__edit-button {
    color: #79d1e3;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.25rem;
    transition: color 0.2s ease;

    &:hover {
      color: #5bc0d5;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(121, 209, 227, 0.5);
      border-radius: 0.25rem;
    }
  }

  &__modal {
    position: absolute;
    top: 100%;
    left: 0;
    width: 18rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border: 1px solid #e5e7eb;
    padding-top: 1rem;
    z-index: 10;
    margin-top: 0.5rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__form-group {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #6b7280;
  }

  &__input-group {
    display: flex;
    align-items: center;
  }

  &__input {
    flex: 1;
    border: 1px solid #d1d5db;
    border-right: none;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;

    &:focus {
      outline: none;
      border-color: #79d1e3;
      box-shadow: 0 0 0 1px #79d1e3;
    }
  }

  &__currency {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    background-color: #f3f4f6;
    border: 1px solid #d1d5db;
    border-left: none;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    font-size: 0.875rem;
    color: #6b7280;
  }

  &__discount-group {
    display: flex;
    gap: 0.5rem;
  }

  &__discount-percent {
    width: 5rem;
  }

  &__discount-amount {
    flex: 1;
  }

  &__final-price {
    font-size: 1.125rem;
    font-weight: 600;
    color: #333;
    margin: 0;
  }

  &__actions {
    display: flex;
    // gap: 0.5rem;
    margin-top: 1rem;
  }

  &__button {
    flex: 1;
    padding: 0.2rem 1rem;
    border: none;
    border-radius: 0;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &--cancel {
      background-color: #e5e7eb;
      color: #374151;

      &:hover {
        background-color: #d1d5db;
      }
    }

    
  }
}
