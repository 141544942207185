.treatment-view-container {
    margin: 20px 20px;
  text-align: left;
    .heading {
        color: #000;
        font-family: "Poppins", "Mulish";
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
    }

    .treatment-view-info{
    text-align: left;
    border-radius:  4px;
     background: #FAFAFA;
     border: none;
     display: inline-flex;
     margin: 30px 0px;

    }
    .treatment-products-view{
        .treatment-product-view{
            padding: 25px 20px;
            border-radius: 4px;
            border-bottom: 1px solid #EDEDED;
        .form-info-primary{
            border-radius: 8px;
            padding: 8px 18px;
            color: #fff;
            font-weight: 700;
            margin: 0px;
            display: inline-block;
            font-size: 14px;
        }

        .form-info-label {
            font-size: 16px;
            font-weight: 500;
            display: block;
            color: #000;
            text-align: left;
            margin-bottom: 15px;
            padding-left: 10px;
        }
    
        .form-info-value {
            font-family: 'Mulish';
            color: #000;
            font-size: 15px;
            margin: 0px;
            padding: 10px 10px;
            background: #FAFAFA;
             display: inline-flex;
             font-weight: 600;
        }
        &:hover{
            background: #F9F9F9;
        }
        }
    }

}
