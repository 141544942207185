.form-label {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin: 0px 0px 8px 0px;
}

.appointment-form{
    :global(.ant-form-item){
        margin-bottom: 10px;
       }
}
