.help-support-container {
  padding: 40px;
  // background-color: #f7f8fa;
}

.support-title {
  text-align: start;
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  margin-top: 0px;
}

.patient-app-help-text {
  text-align: start;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 15px !important;
  color: #77888B;
}

.help-support-row {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh; // Ensures vertical centering
}

.image-column {
  display: flex;
  justify-content: flex-end; // Align image to the right side of the column
  text-align: center;

  .phone-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .phone-image {
    max-width: 150px;
  }
}

.form-column {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .help-support-header {
    font-size: 1.8rem;
    color: #333;

    .highlight-text {
      color: #00C6E9;
    }
  }

  .help-support-subtitle {
    font-size: 1rem;
    color: #77888b;
    margin-bottom: 30px;
  }

  .help-support-form-item {
    margin-bottom: 20px;
  }

  .help-support-submit {
    text-align: center;
  }

  .help-support-confirm-button {
    width: 100%;
    background-color: #00bfff;
    border-color: #00bfff;

    &:hover {
      background-color: #009fd1;
      border-color: #009fd1;
    }
  }
}