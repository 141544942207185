.userColTwo {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .register-image{
        width: 40%;
    }
    .info-image{
        width: 60%;
    }

    .heading {
        font-size: 1.5rem;
        font-family: Poppins, sans-serif;
        margin-top: 0px;
    }

    .description {
        font-size: 1rem;
        font-family: Mulish;
        margin: 0px;
        line-height: 25px;
    }
    .resendBtn{
        height: 2.5rem !important;
        margin-top: 1.5rem;
    }
}