.management-form-container {
    margin: 20px 20px;

    .heading {
        color: #000;
        font-size: 20px;
        font-weight: 400;
        margin: 0px;
    }

    .form-label {
        text-align: left;
        margin: 40px 0px 20px 20px;
        color: #000;

        font-size: 16px;
        font-weight: 600;
    }

    .management-form {
        margin: 20px 0px;
    }
    .required-input {
        &::before {
            border-radius: 50%;
            display: flex;
            content: "*";
            color: red;
            position: absolute;
            left: -10px;
            top: 10px;
            font-size: 16px;
        }
      }
    .primary-radio{
        text-align: left;
        position: relative;
        .radio-label{
            position: absolute;
            top: -30px;
            font-family: 'Poppins';
            font-weight: 400;
        }
    }
    :global(.ant-form-item){
     margin-bottom: 10px;
     position: relative;
    }
}