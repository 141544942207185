.payment-view-container {
    margin: 20px 20px;
    text-align: left;
    .heading {
        color: #000;
        font-size: 20px;
        font-weight: 400;
        margin: 0px;
    }

    .payment-view-info{
        text-align: left;
        border-radius:  4px;
         background: #FAFAFA;
         border: none;
         display: inline-flex;
         margin: 30px 0px;
    
        }

        .empty-container {
            margin: 20px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;

            h2 {
                margin: 10px 0px 0px 0px;
                font-family: "Poppins", sans-serif;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
            }

            p {
                font-weight: 400;
                margin: 0px 0px 10px 0px;
                font-family: "Poppins", sans-serif;
                font-size: 18px;
                font-weight: 400;
                margin-top: -5px;
            }
        }
    .form-user-phone {
        text-align: right;
        margin-top: -15%;
        svg {
            width: 30%;
            height: 30%;
        }
    }

}