.payment-view-container {
    text-align: left;
    padding: 0px 2rem 4rem 2rem;
    margin: 20px 20px;
    text-align: left;


    label{
        font-size: 13px;
    }

    .heading {
        color: #000;
        font-family: "Poppins", "Mulish";
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
    }

    .book-appointment {
        border-radius: 4px;
        background: #E8FBFF;
        padding: 14px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0px;
        gap: 1.5rem;
        margin-right: -52px;
        label {
            color: #000;
            font-size: 16px;
            font-weight: 400;
        }
        span {
            color: #000;
            font-size: 16px;
            font-weight: 700;
        }
    }
    .treatment-view-info{
        text-align: left;
        border-radius:  4px;
         background: #FAFAFA;
         border: none;
         display: inline-flex;
         margin: 10px 0px 30px 0px;
    
        }
    
}

@media (max-width: 1000px) {
    .payment-view-container  {
        padding: 0px;
        margin: 20px 20px;
      }
      .payment-view-container .book-appointment {
       margin-right: -20px;
          }
      .payment-view-container .book-appointment label {
    font-size: 14px;
      }

  }