.senderComment {
  float: right;
  min-width: 60%;
  max-width: 14rem;
  position: relative;

  h5 {
    margin: 0px !important;
    font-size: 14px;
    line-height: auto;
  }

  p {
    margin: 0px !important;
    line-height: auto;
  }

  .date {
    text-align: right;
    display: block;
    color: #fff;
    font-size: 12px;
    margin-top: 5px;
  }

  .name {
    font-weight: 600;
    text-align: left;
    display: block;
    color: #fff;
    font-size: 12px;
    margin: 0px 0px 5px 0px;
  }
}

.senderHistory {
  // background: #f6f6f6 !important;
  border-radius: 10px;
  padding: 10px;

  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  .text, a {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    margin: 0px;
    color: #fff;

  }
}

.receiverComment {
  min-width: 60%;
  max-width: 14rem;
  float: left;
  position: relative;

  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  .senderHistory {
    background: #ffff;
    border-radius: 10px;
    padding: 10px;
    .text {
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      margin: 0px;
      color: #000;
  
    }
  }

  h5 {
    margin: 0px !important;
    font-size: 14px;
    line-height: auto;
  }

  p {
    margin: 0px !important;
    line-height: auto;
  }

  .date {
    text-align: right;
    display: block;
    color: #A1A8B0;
    font-size: 12px;
    margin-top: 5px;
  }

  .name {
    text-align: left;
    display: block;
    color: #000;
    font-size: 12px;
    margin: 0px 0px 5px 0px;
    font-weight: 600;
  }
}

.receiverHistory {
  background-color: #e9e9e9;
  border-radius: 10px;
  padding: 10px;

  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
}

.ant-tabs-nav-list {
  //overflow: scroll;
}

.tab-header-patient-name {
  margin: 0;
}

.tab-header-lab-name {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 150px;
  text-overflow: ellipsis;
}

.tab-header-receiver-name {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 250px;
  text-overflow: ellipsis;
}

.comment-editor {
  .ql-editor {
    font-weight: 500;
    max-height: 110px !important;
    overflow-y: auto;
  }
}
