@import '../variables.scss';

.days-off-input-container {
  border: 0.1px solid #cfcfcf;
  display: flex;
  justify-content: space-between;
  //   margin-left: 2rem;
  border-radius: 3px;

  background-color: #f9f9f9;
}

// .days-off-input-container:hover {
//   border-color: $primaryColor;
// }

.days-off-container {
  border-radius: 6px;
  width: 100%;
  padding-bottom: 0.5rem;
}
