.labOrderModal-container{
    .dynamic-products{
        border: 1px dashed #D7D6D6;
        border-radius: 10px;
        margin:10px 0px;
        .cost-container{
          position: relative;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 5px;
          label{
            color: rgba(0, 0, 0, 0.88);
          }
        }
        .border-line{
          border-top: 1px dashed #D7D6D6;
          width: 100%;
        }
        .totals{
          .total-label{
            color: #9CA5BC;
            font-size: 16px;
           margin: 10px 0px;  
           text-align: left;   
          }
          h2{
            font-size: 14px;
            font-weight: 700;
            margin: 0px 0px 15px 0px;
            text-align: left;   
          }
        }
        .ant-input-number{
          width: 100%;
        }
        .ant-form-item{
        margin-bottom: 14px !important;
        }
        .addOn-btn {
          display: flex;
          align-items: center;
          border: none;
          color: #828282;
          font-weight: 400;
          box-shadow: none;
          margin-left: auto;
          padding: 0px;
          svg {
              margin-right: 0px;
          }
      }
       }
}