@import "../variables.scss";

.rest{
  margin-left: unset !important;
}
.treatment-plan {
 margin-left: -300px !important;
}

.inprogress {
  margin-right: 100px !important;
 }


.rest {
  margin-left: -11% !important;
  @media only screen and (min-width: 768px) and (max-width: 1500px) {
    margin-left: 0% !important;
  }
}
.rest-pre {
  margin-left: -35% !important;
  @media only screen and (min-width: 768px) and (max-width: 1500px) {
    margin-left: 0% !important;
  }
}
.rest-order {
  margin-left: -15% !important;
  @media only screen and (min-width: 768px) and (max-width: 1500px) {
    margin-left: 0% !important;
  }
}

.patient-journey-menubar {
  justify-content: space-around;
  width: 100%;
  border-radius: 8px;
  .journey-item{
    // margin: -1px 80px 0px;
    margin: -1px 2% 0px;
  }
  // .patient-journey-menubar-items {
  //   &::after {
  //     display: none;
  //   }

  //   &:hover {
  //     border-bottom: 3px solid rgba(5, 5, 5, 0.06) !important;
  //   }
  // }
  }
.patient-journey-menubar-pre {
  border-radius: $borderRadius !important;
  width: 100%;
}

.patient-orders-row{
  width: 100%;
  border-radius: $borderRadius !important;
  background: #fff;
}
