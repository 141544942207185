@import '../variables.scss';
.event-icon {
  color: $primaryColor !important;
}
.event-patient-name {
  margin-top: 0.3rem;
  text-align: left;
  font-weight: 550;
}

.event-appointment-type {
  margin-top: -9px;
  text-align: left;
  color: #798c96;
  font-size: 0.8rem;
}

.event-appointment-icon {
  text-align: left;
  margin-left: 0.1rem;
  margin-bottom: 0.5rem;
  margin-top: -5px;
}
.weekly{

  &:hover{
    .rbc-event-label{
      margin-top: 10px;
    }
    .event-patient-name{
      margin-top: 0px;
    }
  }
}
