.notesInput {
  height: 17rem !important;
  background-color: #fafafa !important;
}
.notesInput textarea {
  height: 17rem !important;
}

.notesModal {
  // right: -29rem !important;
    top:  50% !important;
    height: 27rem !important;
   right: -23%;
}

.note-editor{
  width: 100%;
  height: 250px;
  .quill {
    height: 78%;
  }
  .ql-editor{
    height: 100%;
    max-height: 100%;
  }
}
.treatment-details-notes{
  width: 100%;
  height: 130px;
  margin-top: 10px;
  .quill {
    height: 78%;
  }
}


textarea::-webkit-scrollbar {
  width: 6px !important;
  height: 4px !important;
}

textarea::-webkit-scrollbar-track {
  width: 10px;
  height: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
textarea::-webkit-scrollbar-corner {
  display: none;
}
textarea::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.noteContainer {
  background: #fafafa;
  border-radius: 10px;
  margin: 3px;
  font-size: 16px;
}
