.change-password-container {
  width: 70rem;
  margin-top: -80px;
  margin-left: -30px;
  border-radius: 12px 12px 0 0 !important;
}

.change-password-title-container {
  text-align: left;
  margin-bottom: "3rem";
  margin-top: 2rem;
}

.change-password-form {
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2%;
  border-radius: 0 0 12px 12px;
  background-color: white;
  padding-bottom: 1.5rem;
}
