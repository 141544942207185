.prescriptionCard {
    .ant-card-head {
        background: #fff !important;
        border-radius: 8px;
    }

    .ant-card-body {
        padding: 24px 0px !important;
    }

    .draft-btn {
        color: #F00;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FF00002B;
        border: none;
        margin-left: 10px;

        &:hover {
            color: #F00 !important;
        }
    }

    .version-date {
        color: #77888B;
        font-family: "Mulish", sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
}

.prescription-form-container {
    position: relative;
    width: 100%;
    margin-bottom: 3rem;
    overflow: hidden;

    label {
        color: #000;
    }

    .ant-form-item-explain-error {
        text-align: left !important;
    }

    .form-heading {
        color: #000;
        text-align: left;
        font-size: 15px;
        font-family: "Mulish", sans-serif;
        font-weight: 700;
    }

    .prescription-form {
        .form-select {
            // height: 56px !important;
            text-align: left;
            width: 100%;
        }

        .ant-divider {
            margin: 10px 0 !important;
        }

        .formRow {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;
            overflow: hidden;
            .form-col-two{
                display: flex;
                flex-direction: column;
                border-radius: 8px;
            }
        }

        .ant-select-open {
            .ant-select-arrow {
                transform: rotate(180deg) !important;
                transition: all 0.3s !important;
            }
        }

        .addOn-btn {
            display: flex;
            align-items: center;
            border: none;
            color: #828282;
            font-weight: 400;
            box-shadow: none;
            margin-right: auto;
            padding: 0px;
            background: none;
        }

        .treatment-row {
            .ant-form-item {
                margin-bottom: 37px !important;
            }

            .ant-col {
                text-align: left;
            }

            .delete-btn {
                border: none !important;
                box-shadow: none !important;
                background: none;

                svg {
                    path {
                        fill: rgb(0, 0, 0) !important;
                    }
                }

                &:hover,
                &:active {
                    svg {
                        path {
                            fill: red !important;
                        }
                    }
                }
            }
        }

        .aligner-row {
            padding-left: 15px;
            .num-btn {
                border-radius: 50%;
                text-align: center;
                background: #00C6E9;
                position: absolute;
                left: -25px;
                top: 37px;
                color: #fff;
                padding: 0px 7px;
            }
            .ant-form-item {
                margin-bottom: 14px !important;
            }
            .del-btn {
                svg {
                    width: 15px;
                }
            }
            .ant-select-open {
                .ant-select-arrow {
                    transform: rotate(0deg) !important;
                    transition: all 0.3s !important;
                }
            }

        }

        .instruction-row {
            padding-left: 15px;
            text-align: left;

            .ant-form-item {
                margin-bottom: 29px !important;
            }

            .ant-radio-group {
                display: grid !important;
                grid-template-columns: 1fr 1fr 1fr 1fr !important;
                gap: 5px;
            }
        }

        .bite-row {
            padding-left: 15px;
            text-align: left;
            margin: 15px 0px;

            // .ant-radio-group{
            //     display: flex !important;
            // }
            .ant-radio-group {
                display: grid !important;
                grid-template-columns: 1fr 1fr 1fr  1fr !important;
                gap: 5px;
            }

            .ant-radio-wrapper {
                align-items: center !important;
            }

            .bite-select {
                position: relative;
                display: grid;
                grid-template-columns: 1fr 5fr;

                .ant-select {
                    width: 100% !important;
                    min-width: 10px !important;

                    &::before {
                        content: "As needed";
                        position: absolute;
                        top: -20px;
                        color: #000;
                        font-family: "Mulish", sans-serif;
                        font-size: 12px;
                        font-weight: 300;
                    }
                }

                .ant-form-item {
                    margin-bottom: 0px !important;
                }
            }
        }

        .ipr-row {
            padding-left: 15px;
            text-align: left;
        }

        .incisal-row {
            padding-left: 15px;
            text-align: left;
            // .ant-radio-group {
            //     display: flex !important;
            //     justify-content: flex-start !important;
            // }
        }

        .spacing-row {
            padding-left: 15px;
            text-align: left;
            .ant-radio{
                width: 16px !important;
            }

            .ant-radio-group {
                // display: grid !important;
                // grid-template-columns: 1fr 1fr !important;
             gap: 10px;
             .ant-radio-inner{
                width: 16px !important;
             }
             span{
                // width: 155.78px;
             }
            }
        }

        .bio-row {
            padding-left: 30px;

            .ant-radio-group {
                display: grid !important;
                grid-template-columns: 1fr 1fr 1fr 1fr !important;
                gap: 5px;
            }
        }

        .phasing-row {
            padding-left: 15px;
            .label {
                label {
                    display: flex;
                    align-items: center;
                }

                svg {
                    margin-left: 10px;
                    font-size: 16px;
                    margin-top: 3px;
                        // path {
                        //     fill: #00C6E9;
                        // }
                }
            }

            .ant-radio-group {
                display: grid !important;
                grid-template-columns: 1fr 1fr 1fr 1fr !important;
                   gap: 5px;
            }
        }

     
        .skeletal-row {
            padding: 0px 20px 0px 20px;
            text-align: left;

            .ant-form-item {
                margin-bottom: 0px !important;
            }
        }
    }

    .formFooter {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 26px;
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 2;

        .formRowOne {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            span {
                color: #000;
            }

            a {
                // color: #00C6E9;
                text-decoration: underline;
            }
            .terms{
                min-width: 280px;
                text-align: left;
            }
        }

        .ant-form-item {
            margin-bottom: 0px !important;
        }

        .buttons-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 30px;

            .cancel-btn {
                border-radius: 27px;
                border: 1px solid #BCBCBC;
                color: #000;
                font-size: 16px;
                font-weight: 500;
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: center;

            }

            .submit-btn {

                padding: 10px 20px;

                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                border-radius: 27px;
                background: #00C6E9;
                color: #FFF;
                margin-left: 10px;
            }
        }
    }

}

.prescriptionProductPrice{
    label{
        visibility: hidden;
    }
}