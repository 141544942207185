@import '../variables.scss';
.ant-layout-sider {
  // background-color: $primaryColor !important;
}

.custom-menu{
  li{
    margin: 0!important;
    width: 100%!important;
    text-align: center!important;
    .ant-menu-title-content{
      margin: 0!important;
    }
  }
}

.sidebar-menu-items {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  height: auto !important;
  padding-top: 0.5rem !important;
  // background-color: red;
  // margin-top: 2px !important;
  p{
    margin: 0;
  }
}

.sidebar-menu-items:hover{
  background-color: white;
}

.sidebar-icon {
  font-size: 1.5rem !important;
  color: 'white';
}
.setting-dot {
  &::before{
    content: " ";
    border-radius: 50%;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 18px;
    right: 35px;
    background: red;
    z-index: 1000;

  }

}

.sidebar-container{
  .ant-menu{
    .ant-menu-item{
      border-radius: 0;
    }
  }
}