.clinicianFormContainer {
  padding-bottom: 1.5rem;
  background: #fff;
    .title{
        margin-bottom: 0.5em;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 16px;
        font-family: "Poppins", sans-serif;
        text-align: left;
    }
    p{
        text-align: left;
        color: #798c96;
        // font-family: "Poppins", sans-serif;
        font-size: 14px;
        margin: 5px 0px 0px 0px;
    }
    .addBtn {
        color: #000;
        font-weight: 400;
        box-shadow: none;
        border: 1px solid #C4C4C4;

        svg {
            margin-right: 0px;
        }
    }

    .deleteBtn {
        border: none !important;
        box-shadow: none !important;
        background: none;

        svg {
            path {
                fill: rgb(0, 0, 0) !important;
            }
        }

        &:hover,
        &:active {
            svg {
                path {
                    fill: red !important;
                }
            }
        }
    }
}