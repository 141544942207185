.dragContainer {
  position: relative;
  background-color: black;
  border-radius: 10px;
  height: 100%;
  z-index: 999;
}

.dragContainer:hover {
  cursor: grab;
}

.dragContainer:active {
  cursor: grabbing;
}

.maximize {
  position: absolute;
  top: 10px;
  left: 10px;
}

.minCallControls {
  width: 100%;
  position: absolute;
  left: 32%;
  right: 25%;
  bottom: 10px;
  display: flex;
  margin-bottom: 2%;
  button {
    margin-right: 2%;
  }
}

//Max view

.participant {
  border-radius: 6px;
  display: inline-block;
  height: 150px;
}
.participant:last-child {
  margin-right: 0;
}
.participant h3 {
  text-align: center;
  padding-bottom: 0.5em;
  color: #fff;
}
video {
  width: 100%;
  border-radius: 6px;
  transform: scale(-1, 1);
}

.file-name{
  padding-bottom: 10px;
}
.preview-video{
  video{
    transform: none !important;
  }
}

.roomContainer {
  width: 92%;
  height: 80vh;
  position: relative;
  background-color: #000;
}

.local-participant {
  width: 20%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.remote-participant {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .participant {
    height: 90%;
  }
  video {
    height: 100%;
  }
  .bottomButtons {
    margin: 0 auto;
    width: 15%;
    button {
      margin-right: 10%;
    }
  }
}

.maxContainer {
  position: absolute;
  top: 0;
  margin-left: 10rem;
  width: 95%;
  margin-top: 7rem;
}

.minContainer {
  position: absolute;
  top: 75vh;
  left: 80vw;
  background-color: black;
  border-radius: 10px;
  width: 18%;
  height: 25%;
  z-index: 999;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
