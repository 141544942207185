.menu-container {
    padding: 25px 0px 25px 25px;
    width: 250px;

    .menu-subtitle {
        font-family: 'Mulish';
        color: #77888B;
        font-size: 12px;
        text-align: left;
    }

    .menu-title {
        font-family: 'Mulish';
        color: #000;
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        margin: 0px;
    }
    .red-dot {
        &::before{
            content: "";
            border-radius: 50%;
            width: 8px;
            height: 8px;
            position: absolute;
            top: 22px;
            left: 20px;
            background: red;
            z-index: 1000;
        }
  
      }
      
      .no-dot {
    
      }
    :global(.ant-menu-item-group-title) {
        padding-left: 0px;
        text-align: left;
        margin-bottom: 1rem;
        margin-top: 10px;
    }

    :global(.ant-menu-item) {
        padding: 25px 0px;
        font-family: 'Mulish';
        // color: #77888B;
        font-size: 15px;
        font-weight: 600;
    }

    :global(.ant-menu-title-content) {
        text-align: left;
        padding-left: 20px;
        // color: #77888B;

    }

    :global(.ant-menu-item-selected){
        border-left: 3px solid;
        border-radius: 2px;
    }



}