.progress-image-card {
  height: 100%;
  width: "100%";
  text-align: left;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  min-height: 53.2rem;
  h1{
    font-family: "Mulish", sans-serif;
font-size: 16px;
font-weight: 700;
margin: 0px 0px 10px 0px;
  }
    .image-name {
      font-family: "Mulish", sans-serif;
      font-size: 14px;
      font-weight: 600;
      color: #8E9596;
      margin-bottom: 10px
    }

  .image-upload-container {
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    // max-height: 52.3rem;
    // min-height: 52.3rem;

    .ant-image{
      width: 100% !important;
      height: 175px !important;
    }
    .ant-image-img{
      // height: 250px !important;
    }
    .image-Card {
      padding: 7px;
      box-shadow: #3c40434d 0px 1px 2px 0px, #3c404326 0px 1px 3px 1px;
      border: 0.5px;
      border-style: solid;
      border-radius: 5px;
      height: 300px;

      .ant-upload-wrapper{
        display: flex !important;
        justify-content: center !important;
      }

      // .ant-upload{
      //   margin: 0 auto !important;
      //   display: block !important;
      // }
  }
    .image-dim {
      width: 100%;
       height: 175px;
      object-fit: cover;
      object-position: center center;
      // @media only screen and (min-width: 1200px) and (max-width: 1900px) {
      //   min-width: 210px;
      //   min-height: 150px;
      //   object-fit: cover;
      //   object-position: center center;
      // }
    }
  }
  &.impression-image-card{
    .ant-card-extra{
      padding: 0 !important;
    }
  }

  &.no-impression-list{
    .ant-card-body{
      padding: 0;
    }
  }
  
}

.impressions-image-card{
  .ant-image{
    width: 100%;
  }
  .image-impressions {
    object-fit: contain;
  }
  .impression-approve-container{
    display: flex;
    justify-content: flex-end;
    // flex-direction: column;
    margin-top: 15px;
    .reject-input{
      margin-top: 10px;
    }
  }
}


.progress-drd-item{
  display: flex;
  justify-content: space-between;
}