.partner-view-container {
    margin: 20px 20px;

    .heading {
        color: #000;
        font-family: "Poppins", "Mulish";
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
    }

    .partner-card {
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      width: 100%;
      max-width: 400px;
        text-align: left;
        margin-top: 3rem;
    }

    .form-info-heading {
        font-family: 'Poppins';
        color: #000;
        font-size: 24px;
        font-weight: 700;
        padding: 40px 30px;
        border-radius: 16px 16px 0px 0px;
    }

    .partner-card-info {
        border-bottom: 1px solid #EDEDED;
    }

    .form-info-label {
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 400;
        display: block;
        margin-left: 30px;
        color: #000;
    }

    .form-info-value {
        font-family: 'Poppins';
        color: #000;
        font-size: 16px;
        font-weight: 500;
        margin: 0px 30px;
        font-weight: 600;
        padding: 35px 0px 35px  0px;
    }



}
