.modalButton.ant-btn {
  width: 25%;
  border-radius: 20px;
  font-size: 0.9em;
}

.cancelBtn.ant-btn {
  // border-color: #00c6e9;
  // color: #00c6e9;
}

.labTermsAndConditions {
  margin-top: 10%;
  font-size: 0.85em;
}

.termsAndConsent {
  // color: #00c6e9;
  cursor: pointer;
}
