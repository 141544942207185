.management-view-container {
    margin: 20px 20px;
    .heading {
        color: #000;
        font-family: "Poppins", "Mulish";
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
    }
    .form-info-label{
        font-family: 'Mulish';
        color: #77849E;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        display: block;
        margin: 50px 0px 30px 0px;
    }
    .form-info-value{
        font-family: 'Mulish';
        color: #000;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        margin: 0px;
    }

    .form-info-tag{
       font-family: 'Mulish';
       font-size: 14px;
       border-radius: 8px;
       padding: 7px 18px;
    //    width: 150px;
       color: #fff;
       font-weight: 700;
       margin: 0px;
       display: inline-block;

    }

}



