.availability-settings-container {
  width: 65rem;

  // margin: 0 1rem 1rem 1rem;
  .appointment-off-container {
    background: #fff;
    padding: 2rem;
    position: relative;

    p {
      border-radius: 4px;
      background: rgba(216, 216, 216,0.5);
      padding: 4px 5px;
      color: #000;
      text-align: center;
      font-weight: 500;
      font-family: "Mulish", sans-serif;
      // font-family: "Poppins", sans-serif;
      font-size: 16px;
      z-index: 1;
      position: relative;
      display: inline-block;
      svg{
        path{
          fill : #737678;
        }
      }
   
    }

    h1 {
      color: #000;
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-size: 16px;   cursor: pointer;
    }

    .appointment-backdrop {
      border-radius: 15px;
      background: rgba(255, 255, 255, 0.27);
      backdrop-filter: blur(2px);
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
     
    }

    .appointment-content {
      z-index: 1;
      margin: 3rem 0px;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 15px;
    }
  }
  .availability-clinicUrls{
    background: #fff;
    padding: 0 40px  20px 60px;
    // margin: 0 40px;
    label{
      display: block !important;
      text-align: left;
      margin-bottom: 8px;
    }
  }
}

.working-days-title {
  margin: 1.5rem 0.5rem 0 2rem !important;

  font-family: 'Poppins', sans-serif !important;
}

.off-days-title-container {
  width: 100%;
  // border-bottom-style: solid;

  margin: 0rem 1rem 0 0;
  border-width: 1px;
  border-color: rgba(161, 182, 218, 0.47);
  border-radius: 6px 6px 0 0;

  background-color: white;
}

.off-days-title {
  margin: 0 0.5rem 0 2rem !important;

  font-family: 'Poppins', sans-serif !important;
}

.working-days-container {
  width: 100%;
  // border-bottom-style: solid;

  margin: 0 1rem 0 0;
  border-width: 1px;
  border-color: rgba(161, 182, 218, 0.47);
  border-radius: 6px;
  padding-bottom: 0.5rem;
  padding-right: 1rem;

  background-color: white;
}
