.resources-container {
    .resource-title{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        h3{
            margin: 0px !important;
        }
        .ant-radio-group{

        }
        .ant-radio-button-wrapper{
            min-width: 150px !important;
            text-align: center !important;
        }

    }
}
.addResource-form {
    .ant-upload{
        padding-left: 0px !important;
    }
    .addOn-btn {
        display: flex;
        align-items: center;
        border: none;
        color: #828282;
        font-weight: 400;
        box-shadow: none;
        margin-right: auto;
        padding: 0px;
        svg {
            margin-right: 0px;
        }
    }
    .delete-btn {
        border: none !important;
        box-shadow: none !important;
        background: none;
        svg {
            path {
                fill: rgb(0, 0, 0) !important;
            }
        }
        &:hover,
        &:active {
            svg {
                path {
                    fill: red !important;
                }
            }
        }
    }
    label{
        color: #798c96 !important;
    }
    .ant-upload-list {
        padding-left: 0px !important;
    }
}
.resources-list-container {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.02);
    min-height: 80vh;
    margin-top: 1rem;
    .clickable-row {
        cursor: pointer;
      }
    .ant-table-cell {
        p {
            margin: 0px !important;
        }
    }
    .share-tab {
        border-radius: 17px;
        background: #77888B;
        color: #fff;
        padding: 5px 15px;
        display: inline-block;
        margin-right: 10px;
        margin-top: 5px;
    }
    // .resource-headerRow {
    //     background: #FAFAFA;
    //     height: 60px;
    //     border-radius: 8px;
    //     h1 {
    //         color: #555E6D;
    //         font-family: "Mulish", sans-serif;
    //         font-size: 17px;
    //         font-weight: 800;
    //         margin: 0;
    //         text-align: center;
    //     }
    //     .ant-col {
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //     }
    // }
    .empty-resources-list {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70vh;
    }
    // .resources-list {
    //     .resource-list {
    //         padding: 20px 0;
    //         .share-tab {
    //             border-radius: 17px;
    //             background: #77888B;
    //             color: #fff;
    //             padding: 7px 9px;
    //         }
    //         p {
    //             color: rgba(34, 34, 34, 0.72);
    //             font-family: "Mulish", sans-serif;
    //             font-size: 14px;
    //             font-weight: 700;
    //             margin: 0px;
    //         }
    //         &:hover {
    //             background: rgba(111, 111, 111, 0.05);
    //         }
    //     }
    // }
}
.resource-detail-container {
    position: relative;
    .resource-editBtn {
        position: absolute;
        right: 15px;
        top: 0px;
    }
    .resource-detail {
        margin-bottom: 20px;
        .resources-container {
            margin-top: 1rem;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 10px;
            a {
                max-width: 200px;
            }
            .resource {
                //  margin-right: 1rem;
                a {
                    color: #555E6D;
                    max-width: 25%;
                }
            }
        }
        label {
            color: #555E6D;
            font-family: "Mulish", sans-serif;
            font-size: 17px;
            font-weight: 800;
        }
        p {
            color: rgba(34, 34, 34, 0.72);
            font-family: "Mulish", sans-serif;
            font-size: 16px;
            font-weight: 500;
        }
        a {
            display: block;
        }
    }
}
.addResourceModal {
    .ant-select-selection-item {}
}





