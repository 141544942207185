.treatmentPDF-container {
  // padding: 10px;
  margin-bottom: 17px;
  text-align: left;

  h1 {
    font-size: 18px;
    // text-align: left;
  }

  h3 {
    font-size: 14px;
  }

  label,
  b {
    font-size: 12px !important;
  }

  .treatmentPDF-patientContainer {
    .treatmentPDF-patientRow {
      text-align: left;
      display: flex;
      align-items: flex-start;
      margin-top: 7px;

      label {
        font-weight: 400;
        color: #798c96;
        font-size: 13px;
        text-align: left;
        margin-bottom: 0px;
        font-family: "Mulish", sans-serif;
        min-width: 100px;
      }

      b {
        font-weight: 500;
        color: #222222;
        text-align: left;
        word-break: break-all;
        text-overflow: ellipsis;
        margin-bottom: 0px;
      }

      span {
        font-weight: 400;
        font-size: 13px;
        text-align: left;
        font-family: "Mulish", sans-serif;
        line-height: 20px;
      }
    }
  }

  p {
    margin: 5px 0px;
  }

  .treatmentPDF-footer {
    .patient-question {
      color: #222222;
    }

  }

  .treatment-attachmentInfo-container {
    width: 100%;
    background: #fafafa;
    border: 1px dashed #d7d6d6;
    box-sizing: border-box;
    border-radius: 13px;
    overflow: auto;
    padding: 5px 10px;
  }

  .border-right {
    border-right: 1px solid #d7d6d6;
  }

  .patient-question,
  .patient-response {
    font-size: 12px;
  }

  .ant-divider {
    margin: 8px 0px !important;
  }


  .aligner-component .attachmentInfo-container {

    max-height: 100%;

  }

  .aligner-component .aligner-scroll-container {
    height: 100%;
    padding: 0px;
    overflow: initial;
  }

  .treatment-document-divider {
    margin-top: 25px !important;
    border-block-start: 1px solid #d4d4d4;
  }
}