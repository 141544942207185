.appointment-view-container {
    margin: 20px 3rem 3rem 3rem;
    text-align: left;
    @media screen and (max-width: 1000px) {
        margin: 10px;
      }
    
    
    .heading {
        color: #000;
        font-family: "Poppins", "Mulish";
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
    }
    .book-appointment {
        border-radius: 4px;
        background: #e8fbff;
        padding: 14px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        gap: 1.5rem;
        margin-right: -48px;
      
        label {
          color: #000;
          font-size: 16px;
          font-weight: 400;
        }
      
        span {
          color: #000;
          font-size: 16px;
          font-weight: 700;
        }
      }
      
      @media (max-width: 1000px) {
        .book-appointment  {
          margin-right: -10px;
          }
        .book-appointment label {
          font-size: 14px;
        }
      }
      
   
      .form-info-heading {
        color: #000;
        font-weight: 700;
        font-size: 15px;
      }
      
      @media (max-width: 1000px) {
        .form-info-heading {
          font-size: 14px;
        }
      }
      
    .form-info-label {
        font-size: 14px;
        font-weight: 500;
        display: block;
        color: #798c96;
        text-align: left;
        margin-bottom: 15px;
    }
    p{
        font-weight: 600;
    }
    .appointments-products-view {
        .appointment-product-view {
            padding: 10px 0px;
            border-radius: 4px;
            border-bottom: 1px solid #EDEDED;
            .form-info-value {
                color: #000;
                font-size: 14px;
                font-weight:500;
                margin: 0px;
                padding: 10px 2px 8px 25px;
                background: #FAFAFA;
                position: relative;
                &::before{
                    border-radius: 50%;
                    background: #000;
                    width: 6px;
                    height: 6px;
                    content: '';
                    position: absolute;
                    left: 10px;
                    top: calc(50% - 2px);
                }
            }
        }
    }

}