.forgot-password-container {
  width: 75rem;
  margin-top: -80px;
  margin-left: -30px;
  border-radius: 12px 12px 0 0 !important;
}

// .booking-form-title {
//   margin: 1.5rem 0.5rem 1.5rem 2rem !important;

//   font-family: 'Poppins', sans-serif !important;
// }

// .booking-header {
//   font-family: 'Poppins', sans-serif !important;
// }
.forgot-password-title-container {
  text-align: left;
  // margin-left: 16%;
  margin-bottom: "3rem";
  //   background-color: blue;

  margin-top: 2rem;
}

.forgot-password-form {
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2%;
  border-radius: 0 0 12px 12px;
  background-color: white;
  padding-bottom: 1.5rem;
}
