.questionnaire-responses-component {
  width: 100%;
  background: #fafafa !important;
  border: 1px dashed #d7d6d6 !important;
  box-sizing: border-box !important;
  border-radius: 13px !important;
  padding: 1rem !important;
  //   background-color: red;
}

.patient-question {
  color: #798c96;
  font-weight: 400;
  font-size: 14px;
}

.patient-response {
  color: rgba(34, 34, 34, 0.72);
  font-weight: 800;
  font-size: 14px;
  margin-top: -8px;
}
