@import "../variables.scss";

.profile-steps-container {
  background: #fff;
  border-radius: 6px;
  margin-bottom: 24px;
  width: 100%;
  height: 58px;
  margin-top: 10px;

  .steps-line {
    width: 100%;
    height: 8px;
    border-radius: 3px;
    margin-top: 15px;
    position: relative;
    z-index: 2;
  }
  .profile-steps{
  align-items: center !important;
  padding: 0 2rem;
  }

}
