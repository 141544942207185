.profile-layout-container {
    background: #fff;
    border-radius: 8px;
    min-height: 80vh;
    .profile-content-container {
        background: #fff;
        // border-radius: 8px;
    }
    :global(.ant-layout-sider) {
        flex: 0 0 auto !important;
        max-width: 250px !important;
        width: 250px !important;
    }
}
