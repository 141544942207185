.scanFiles-refinement{
  .scan-file-wrapper {
    border: 1px solid #fff;
    position: relative;
    height: 83px !important;
    position: relative;
  }
  
  .subText {
    font-size: 14px;
    color: #9CA5BC;
    position: absolute;
    top: -12px;
    // margin-top: -15px;
  }
    .ant-upload-list-item-container{
    position: absolute;
    // bottom: -35px;
  }
  .ant-upload-list-item-name{
    width: 80px;
  }

  .scan-file-display {
    position: relative;
    width: 100%;
    height: 83.13px;
    border-radius: 6px;
    border: 1px solid #E1E1E1;
    background: #FAFAFA;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 23px;

    .scan-file-icon {
        svg {
            width: 40px;
            height: 40px;
            margin-top: -20px;
        }
    }

    .fileName {
        color: #404040;
        font-size: 12px;
        font-weight: 400;
        margin: 0px;
        font-size: 13px;
        width: 100%;
        display: block;
        text-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 125px;
        position: absolute;
        bottom: 10px;
        left: 10px;
    }

    .fileMask {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);

        opacity: 0;
        transition: opacity 0.3s;
        // width: 131px;
        // height: 131px;
        border-radius: 8px;

        span {
            padding-left: 3px;
        }

        .downIcon {
            font-size: 20px;
            margin-right: 1rem;
            cursor: pointer;
        }

        .viewIcon {
            font-size: 20px;
            margin-right: 1rem;
            cursor: pointer;
        }
    }

    &:hover {
        .fileMask {
            opacity: 1;
        }
    }
}
}


