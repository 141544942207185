.onboarding-container {
  //width: 60rem;
  margin-left: 6%;
  margin-right: 6%;
  //margin: 1rem auto 0 auto;
  .ant-form-item .ant-form-item-label > label {
    color: #798c96;
  }
  // background-color: red;
  .onboarding-title{
    align-items: center;
  }
 h3{
  margin: 0px;
 }
}

.onboarding-footer{
  margin-left: 6%;
  margin-right: 6%;
  border-radius: 6px 6px;
  background-color: white;
  margin-top: 1rem;
    height: 57px;
    padding: 0px 40px;
    .ant-btn{
      margin-top: 8px !important;
    }
}
