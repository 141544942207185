#container {
  margin-top: 1%;
  background-color: #fff;
  border-radius: 6px;
  padding: 3% 2.75%;
  width: 65rem;
}

#top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#button {
  border-radius: 25px;
}

#table {
  margin-top: 2%;
}

#menuIcon {
  padding: 4px;
  cursor: pointer;
  text-align: center;
}

.menuItem {
  display: flex;
  align-items: center;
}

.menuItem p {
  margin-left: 12%;
}

.noClinicians {
  text-align: center;
  color: #c4c4c4;
  margin-top: 6%;
  margin-bottom: 6%;
}
