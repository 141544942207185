.appointment-view-container {
    margin: 20px 20px;
    text-align: left;

    .appointments-products-view {
        .form-info-label {
            font-size: 16px;
            font-weight: 500;
            display: block;
            color: #000;
            text-align: left;
            margin-bottom: 15px;
        }

        .appointment-product-view {
            padding: 10px 0px;
            border-radius: 4px;
            border-bottom: 1px solid #EDEDED;
            .form-info-value {
                color: #000;
                font-size: 14px;
                font-weight:400;
                margin: 0px;
                padding: 10px 2px 8px 25px;
                background: #FAFAFA;
                position: relative;
                &::before{
                    border-radius: 50%;
                    background: #000;
                    width: 6px;
                    height: 6px;
                    content: '';
                    position: absolute;
                    left: 10px;
                    top: calc(50% - 2px);
                }
            }
            .form-info-desc{
                color: #000;
                font-size: 14px;
                font-weight:400;
                margin: 0px;
                padding: 10px 2px 8px 25px;
                background: #FAFAFA;
                position: relative;
            }
        }
    }
    .empty-value{
        width: 100%;
        height: 25px;
    padding: 10px 2px 8px 25px;
    background: #FAFAFA;
    }

}
