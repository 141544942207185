.patient-details-radio-buttons {
  color: #77849e;
  width: 10rem !important;
  height: 90% !important;
  padding-top: 0.1rem !important;
  white-space: nowrap;       /* Prevent text wrapping */
  overflow: hidden;          /* Hide overflowing content */
  text-overflow: ellipsis;
  // &:hover{
  //   color: #fff !important;
  //   background: #00c6e9;
  //   border-color: #00c6e9;
  // }
}

.commentsModal {
  right: -32rem !important;
  top: 11rem !important;
  height: 27rem !important;
}
.floatingButtons {
  position: fixed !important;
  float: right;
  z-index: 10;


  .ant-float-btn-circle{
    right: 13rem;
    bottom: 0px;
    width: 130px;
    height: 45px;
    border-radius: 8px !important;
    min-width: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffff;
    .ant-float-btn-body{
      border-radius: 8px !important;
      &:hover{
        border-radius: 8px !important;
      }
    }
  }
}

.send-app-confirmation-modal {
  .ant-modal-body {
    padding-bottom: 24px !important;
  }
}

.smiyle-nation-container {
  .patient-detail-container {
    height: 430px;
  }
  .patient-detail-bottomRow-itemOne,
  .patient-detail-bottomRow-itemTwo {
    // height: 285px;
  }
}
.print-assessment-card {
  .ant-card-head {
    padding: 0;
  }
  .ant-tabs-tab-btn {
    font-weight: 600;
  }
  .ant-tabs-nav-list {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    width: 100% !important;
    margin-top: 10px;
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
  }
  .ant-card-body {
    padding: 0;
  }
  .tab-assessment-card {
    .ant-tabs-tab {
      justify-content: center;
      margin-left: 0;
    }
    .ant-tabs-nav{
      display: block !important;
    }
    .ant-tabs-nav-operations{
      display: none !important;
    }
  }
}

.padding-class {
  padding: 0 2rem;
}

.print-form-images-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px 2rem;
  gap: 10px 10px;
  .print-form-images {
    height: auto;
    width: 100%;
    object-fit: contain;
  }
}
.insurance-btn {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5rem !important;
  font-family: "Mulish", sans-serif;
  margin-bottom: -7px;
}
.patient-payment-status-select {
  width: 80%;
  max-width: 200px;
  height: 35px;
  margin-top: 1px;

  .ant-select-selector {
    height: 35px !important;
    // background-color: rgb(221, 250, 255) !important;
    border-radius: 10px !important;
    // border-color: rgb(221, 250, 255) !important;
    // color: rgb(5, 162, 189);
  }
  .ant-select-selection-item {
    line-height: 32px;
  }

  &.modal-select-payment {
    margin-left: 10px;
    width: 125px !important;
    .ant-select-selection-item {
      padding-top: 4px !important;
    }
    &.ant-select-single {
      height: 37px !important;
    }
  }
}

.insurance-modal {
  .ant-modal-body {
    min-height: 53vh;
    max-height: 55vh;
    overflow: auto;
  }
}

.insurance-header-upload {
  display: flex !important;
  justify-content: flex-end !important;
}

.insurance-images {
  height: 140px !important;
  width: 140px !important;
  object-fit: contain;
  margin-right: 10px;
}

.insurance-list-label {
  font-weight: 500;
  padding-top: 10px;
}

.custom-file-list-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  a {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .image-mask {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.3s;
      &:hover {
        opacity: 1;
      }
    }
  }
  .custom-file-list {
    width: 100%;
    height: 100%;
    min-height: 140px;
    .filename {
      svg {
        font-size: 20px;
        margin-bottom: 10px;
      }

      margin-right: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.insurance-btn {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5rem !important;
  font-family: Mulish;
  margin-bottom: -7px;
  // margin-top: 10px;

  &.paid-insurance {
    margin-top: -12px;
  }
}

.patient-payment-status-select {
  width: 130px !important;
}
.button-content {
  overflow: visible;
}
@media only screen and (max-width: 1750px) {
  .button-content {
    overflow: visible;
  }
  .patient-detail-container {
    // height: 388px;
  }
  .patient-detail-bottomRow-itemTwo {
    // height: 255px;
  }
  .patient-detail-bottomRow-itemOne {
    // height: 255px;
  }
  .bottom-bar {
    margin-top: 60px !important;
  }
  .insurance-btn {
    position: absolute !important;
    top: 40px;
  }
  .patient-payment-status-select {
    width: 80% !important;
  }
}

.custom-select {
  .ant-select-item-option-active {
    font-weight: 500;
  }

  .ant-select-selection-item {
    svg {
      display: none;
    }
  }
}

.lab-terms {
  margin-bottom: 0 !important;
  .termsAndConsent {
    // text-decoration: underline;
    border-bottom: 2px solid #00c6e9;
    a {
      color: #00c6e9;
    }
  }
}

.consent-form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 10px;
  padding: 1rem;
  overflow: hidden;
 

  .consent-form-wrapper {
    width: 100%;
    background: #fafafa !important;
    border: 1px dashed #d7d6d6 !important;
    box-sizing: border-box !important;
    border-radius: 13px !important;
    padding: 1rem !important;

    .consent-title {
      font-size: 18px;
      font-weight: bold;
      text-transform: capitalize;
    }
    .consent-date {
      font-weight: 500;
      font-size: 13px;
    }
    .consent-details {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 500px;
      h2 {
        font-size: 18px;
        font-weight: 700;
      }
      h3 {
        font-size: 16px;
        font-weight: 700;
      }
      h2,
      h3 {
        margin-bottom: 0px;
        font-family: "Mulish", sans-serif;
      }
      pre {
        white-space: pre-wrap; /* CSS3 */
        white-space: -moz-pre-wrap; /* Mozilla, old versions */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-word;
        margin: 0px;
        color: #343434;
        font-family: "Mulish", sans-serif;
        font-size: 14px;
      }
    }
  }
}

.consent-form-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.download-button-consent {
  color: rgba(0, 0, 0, 0.88);
  font-size: 15px;
  font-weight: 500;
  &:hover {
    color: rgba(0, 0, 0, 0.88) !important;
  }
}

.print-assesment-container{
  margin-top: 10px;
  padding: 1rem  1rem 0 1rem;
}