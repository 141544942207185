@media only screen and (max-width: 992px) {
  .header-logo {
    margin-left: 0.8rem;
  }
}

.header-logo {
  width: 11.1rem;
  height: 3rem;

  margin-top: 0.5rem;
  margin-left: 1rem;
  object-fit: contain;

  &.clearx-logo{
    margin-left: 0 !important;
  }
}

.device-logo {
  height: 3rem;

  margin-top: 0.5rem;
  margin-left: 1rem;
}

.titleSpan{
  display: flex;
  gap: 10px;
  align-items: center;
}
.ant-form-item-required{
  color: #798c96 !important;
}
.divider {
  border-top: 2px solid rgb(233, 233, 233);
  padding-top: 10px;
}
label{
  color: #798c96;
}
.ant-modal-body{
  padding-bottom: 0px !important;
}



@media only screen and (min-width:1440px) {
  .searchBox{
    width: 90%;
    display: flex;
    margin-left: auto;
    margin-right: 13px;
  }
}