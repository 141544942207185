.payment-form-container {
    text-align: left;
    padding: 0px 2rem 0px 2rem;
    margin: 20px 20px;
      .heading {
        color: #000;
        font-family: "Poppins", "Mulish";
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
    }

    .treatment-view-info {
        text-align: left;
        border-radius: 4px;
        background: #FAFAFA;
        border: none;
        display: inline-flex;
        // margin: 30px 0px;
    }
    .book-appointment {
        border-radius: 4px;
        background: #E8FBFF;
        padding: 14px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        gap: 1.5rem;
        margin-right: -52px;
        label {
            color: #000;
            font-size: 16px;
            font-weight: 400;
        }
        span {
            color: #000;
            font-size: 16px;
            font-weight: 700;
        }
    }
    .required-input {
        position: relative;
        &::before {
            border-radius: 50%;
            display: flex;
            content: "*";
            color: red;
            position: absolute;
            left: -20px;
            top: 10px;
            font-size: 16px;
        }
      }
    :global(.ant-form-item) {
    margin-bottom: 0px;
    }
}

@media (max-width: 1000px) {
    .payment-form-container {
        padding: 0px;
    }
    .payment-form-container .book-appointment {
        margin-right: -20px;
    }
    .payment-form-container .book-appointment label {
        font-size: 14px;
    }

}