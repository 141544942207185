.assessment-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 1rem 0 2rem;
}

.patient-info {
  width: 100%;
  background: #fafafa;
  border: 1px dashed #d7d6d6;
  box-sizing: border-box;
  border-radius: 13px;
  margin: 1.5rem 0 0 0;

  .patient-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    justify-self: flex-start;
  }
}

.first-heading {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.sub-heading {
  font-size: 13px;
  color: #7a8c96;
  margin: 0;
}

.clinic-info {
  justify-self: flex-end;
  text-align: end;
  color: #7a8c96;
  font-weight: 700;
  font-size: 14px;
}

.upper-case {
  text-transform: capitalize;
}

.color-divider {
  height: 2px;
  margin: 0;
  background: #00c6e9;
}

.assesment-inf-cells {
  .info-type {
    font-size: 13px;
    font-weight: 600;
    color: #82939de0;
  }
}

.tertiary-text {
  font-style: italic;
  color: #7a8c96db;
  margin-bottom: -5px;
  letter-spacing: -1.2px;
}

.pre-styles {
  page-break-inside: avoid;
}
