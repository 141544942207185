.screen-container {
    // cursor: pointer;
    .congrats-title {
        margin-bottom: 10px;
        color: #000;
        font-family: "Poppins", sans-serif;
        font-size: 28px;
        font-weight: 600;
        text-align: left;
    }

    .icon-container {
        display: flex;
        /* Use flexbox to arrange icons */
        gap: 8px;
        /* Add space between icons */
        align-items: center;
        // float: right;
        /* Center the icons vertically */
    }

    .advanced-setup-icon {
        font-size: 16px;
        /* Adjust the icon size as needed */
    }

    .instruction-text {
        margin-bottom: 20px;
        color: #77888B;
        text-align: left;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-style: normal;
        display: flex;

    }

    .icon-container {
        background-color: transparent;
        display: flex;
        justify-content: flex-end;

        .anticon-large {
            font-size: 40px; // Larger icon size
            color: white;
        }

        .anticon-small {
            font-size: 20px; // Smaller icon size
            color: white;
            position: absolute;
            top: 55px; // Adjust vertical positioning
            left: 35px; // Adjust horizontal positioning
        }
    }


    .options-container {
        // margin-top: 20px;
        // display: flex;
        // flex-direction: column;

        .congrats-col {
            margin-bottom: 15px;

            .success-image {
                max-width: 100%;
                height: 90%;
                position: relative;
                right: -100px;
            }
        }



        // Add Patient Section Styles
        .add-patient-col {
            margin-bottom: 25px;

            .ant-card {
                .ant-card-body {
                    padding: 10px 20px;
                }
            }

            .add-patient-card {
                // background-color: #E8FBFF;

                .left-section {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                border-radius: 16px;
                // padding: 20px;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                transition: all 0.3s;

                &:hover {
                    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
                }

                .add-patient-left {
                    display: flex;
                    align-items: center;

                    .icon-container {
                        background-color: transparent;
                        display: flex;
                        justify-content: flex-end;

                        .anticon {
                            color: white;
                            font-size: 48px; // Adjust the icon size
                        }

                        &:hover {
                            background-color: transparent; // Slightly darker on hover
                        }
                    }

                    .add-patient-icon {
                        font-size: 48px;
                        color: #008dff;
                        margin-right: 15px;
                    }

                    .add-patient-content {
                        text-align: left;
                        padding-left: 20px;


                        .add-patient-title {
                            color: #000;
                            font-family: "Poppins", sans-serif;
                            font-size: 24px;
                            font-weight: 400;
                            margin: 0px;
                        }

                        .add-patient-description {
                            color: #8c8c8c;
                            font-family: "Poppins", sans-serif;
                            color: #000;
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }

                .add-patient-right {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .add-patient-image {
                        max-width: 70%;
                        height: 100%;
                        margin-left: 15%;
                        border-radius: 10px;
                        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0);
                    }
                }
            }
        }

        // Advanced Setup Section Styles
        .advanced-setup-col {
            .advanced-setup-card {
                // margin-top: 30px;
                 background-color: #F5F5F5;
                 border-radius: 16px;
                 padding: 15px 20px;
                // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                // transition: all 0.3s;

                // &:hover {
                //     box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
                // }
                .ant-card-body {
                    padding: 0;
                    border-radius: 16px;
                }

                .advanced-setup-left {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-inline: 20px;

                    .advanced-setup-icon {
                        font-size: 48px;
                        color: #000;
                        margin-right: 15px;

                    }

                    .advanced-setup-content {
                        text-align: left;

                        .advanced-setup-title {
                            margin: 0px;
                            font-family: "Poppins", sans-serif;
                            font-size: 24px;
                            font-weight: 400;
                        }

                        .advanced-setup-description {
                            color: #000000;
                            font-size: 14px;
                            font-family: "Poppins", sans-serif;
                            font-weight: 400;
                            font-style: normal;
                        }
                    }
                }
            }
        }
    }

    .finish-col{
        display: flex;
        justify-content: flex-end;
    }
    .finish-btn {
        margin-top: 40px;
    }
}