
.ql-editor{
  font-weight: 500;
  max-height: 120px;
  overflow-y: auto;
  min-height: 100px;
  &::before{
      font-style: normal !important;
  }
}

.ql-toolbar {
  text-align: left;
}
.ql-container {
  border-radius: 4px;
}

.quill-readonly .ql-container {
  background-color: whitesmoke;; /* Light gray background for disabled state */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  color: rgba(0, 0, 0, 0.25);
}

.quill-readonly .ql-editor {
  color: rgba(0, 0, 0, 0.25); /* Light gray text color for disabled state */
}
