.rbc-header {
  height: 4rem;
  background-color: #f9f9f9;
  // padding: 1.4rem 0 0 3.8rem;
  // padding: 1rem auto 0 auto;
  font-weight: bolder;
  color: #223032;
}

.rbc-timeslot-group {
  height: 5rem;
  color: black;
}

.rbc-event {
  color: black;
  border-style: none !important;
  // border-left: 2px solid rgba(0, 0, 0, 1) !important;
  height: auto !important;
}
.rbc-time-view {
  border-radius: 0 0 6px 6px !important;
}

.rbc-toolbar-label {
  font-weight: 550 !important;
  color: black !important;
  margin-top: 3px !important;
  font-size: large;
  font-family: Mulish;
}

.rbc-header {
  .rbc-today {
    .rbc-now {
      color: red !important;
    }
  }
}

.react-big-calendar {
  height: 70vh !important;

  background-color: white !important;
  //margin-top: 1rem !important;
  border-radius: 6px !important;
  // width: 95% !important;
}
.rbc-event {
  border: 1px solid grey !important;
}
.weekly:hover {
  transform: scale(1.5);
  z-index: 3;
}
.rbc-row-segment {
  .rbc-event {
    max-height: 2rem !important;
    width: auto;
  }
}
.rbc-time-header > .rbc-row:nth-of-type(2) {
  display: none !important;
}
.week{
  line-height: 20px;
  margin-top: 10px; 
  margin-right: 10px; 
  font-family: Mulish;
  background: #f9f9f9;
}
.selectview{
  margin-top: 10px !important;
  //margin-left: 59% !important;
  width: 100%;
  //float: right !important;
  
}
.arrowButton:hover,
.arrowButton:focus{
  border-radius: 25px !important;
}
.rbc-header-gutter{
  background: #ECECEC;
}