$secondary-color: #77888b;

.payment-row {
  display: grid;
  grid-template-columns: 5fr 2fr 1fr 1fr;
}

.heading-text {
  color: $secondary-color;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.btn-name {
  color: $secondary-color;
  text-align: center;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 6px;
}

.payment-name {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.active-col {
  align-self: center;
}

.default-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.edit-button {
  align-self: flex-start;
  justify-self: center;
}

.delete-icon{
  &:hover{
    cursor: pointer;
  }
}

.col-two {
  align-self: center;
}
.add-payment-btn-container {
  text-align: center;
  width: 100%;
  margin-top: 3rem;
}


.url-text{
  width: 400px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis;
  color: $secondary-color;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

