@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap");

.booking-form-container {
  //width: 65rem;
  // margin: 0 1rem 1rem 1rem;
  // margin-top: 1rem;
  .compact-select {
    .ant-select-selector {
      border-radius: 4px 0 0 4px;
    }
  }
  .booking-form-content{
    background: #fff;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1.5rem;
    // border-radius: 0px 6px;
  }
}

.booking-form-title {
  font-family: "Poppins", sans-serif !important;
}
.booking-form-title-container {
  width: 100%;
  // border-bottom-style: solid;

  margin: 0 1rem 0 0;
  border-width: 1px;
  border-color: rgba(161, 182, 218, 0.47);
  border-radius: 6px 6px 0 0;

  background-color: white;
}

.parent-form-item {
  margin-bottom: unset !important;
  width: 100% !important;
  margin-top: 8px;
}
.product-label-hide{
  label{
    visibility: hidden;
  }
}

.price-row{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 1rem;
  position: relative;
  .ant-form-item-label{
    overflow: visible !important;
  }
  .col-two{
    display: grid;
    grid-template-columns: 4fr 5fr;
    gap: 10px;
    align-items: flex-end;
  }
}

.booking-form {

  font-family: "Mulish", sans-serif;
  font-weight: 400;
  .support-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    .ant-form-item{
      margin: 0px !important;
    }
  }
}
.test {
  .ant-input:focus {
    border-style: none !important;
    border-color: white !important;
  }

  .ant-form-item {
    border-style: solid !important;
    margin-bottom: unset !important;
  }
}
.Next-Cancel-Btn-Container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 10px;
}
@media only screen and (max-width: 992px) {
  .booking-form-container {
    margin-top: 1rem;
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .inline-form-left {
    display: block !important;
    width: auto !important;
    margin: 0 0.5rem 0 0 !important;
  }
  .inline-form-right {
    display: block !important;
    width: auto !important;
    margin: 2rem 0.5rem 1.5rem 0 !important;
  }
  .booking-form-title {
    margin: 1.5rem 1rem 1.5rem 1rem !important;
  }
  .booking-form {
    padding-left: 1rem;
    .ant-form-item-label {
      label {
        color: #798c96 !important;
      }
    }
  }
}

.delete-icon {
  &:hover {
    color: red;
    cursor: pointer;
  }
}

.consent-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  overflow: hidden;
  align-items: flex-start;
  background: #fff;
  padding-right: 2rem;
  padding-bottom: 1.5rem;

  .consent-uploader {
    button {
      cursor: pointer;
    }

  }

  .title {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    text-align: left;
  }

  .fileImage {
    position: relative;
    width: 100px;
    height: 100px;

    svg {
      width: 100%;
      height: auto;
    }

    .fileMask {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.3s;
      width: 100%;
      height: auto;
      border-radius: 8px;

      .icons {
        padding-left: 10px;
        cursor: pointer;
      }
    }

    &:hover {
      .fileMask {
        opacity: 1;
      }
    }
  }
}
