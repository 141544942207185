@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap");

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.treatment-form-container {
  background-color: #ffffff;
  width: 100%;
  margin-top: 50px;
  border-radius: 8px;
}

.scan-form-container {
  width: 100%;
  border-radius: 8px !important;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // column-gap: 15px;

  .scanFiles-col {
    background-color: #ffffff;
    border-radius: 8px;

    .ant-divider {
      margin: 10px 0 !important;
    }

    .scanFiles-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 14px 0px 20px;

      .ant-select {
        width: 178px;
        text-align: left;
      }

      label {
        color: #77888b;
        font-size: 14px;
        font-weight: 400;
        padding-right: 20px;

        span {
          color: #f00;
        }
      }

    }
  }

  .clinicFiles-col {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;

    .ant-divider {
      margin: 10px 0 !important;
    }

    .clinic-file-upload {
      
      .ant-upload {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        background: rgba(0, 0, 0, 0.02);
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        cursor: pointer;
        transition: border-color 0.3s;
    
      }

      .ant-upload-select {
        position: absolute;
        top: 27px;
        left: 19px;
        height: calc(66% - 5px);
        width: calc(23% - 6px);
        background: rgba(0, 0, 0, 0.02);
      }

      .scan-upload-text {
        color: rgba(34, 48, 50, 0.72) !important;
        text-align: center;
        font-family: "Mulish", sans-serif;
        font-size: 12px !important;
        padding: 0 18px;
        font-weight: 600;
        line-height: normal;
      }

      .ant-upload-list-picture-card {
        // margin-left: 180px;
        margin-left: 23%;
        display: flex;
        overflow-x: auto;
        height: 165px;
        row-gap: 40px;
        flex-direction: row;
        flex-wrap: nowrap;
      }

      .clinical-file-name {
        margin: 0px;
        font-size: 13px;
        width: 100%;
        display: block;
        text-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
      }
      // .ant-upload{

      //
      // }
    }
  }

  .ant-card-body {
    display: flex;
    flex-direction: row;
  }
}

.treatment-form {
  width: 100%;
}

.parent-item {
  width: 100%;
  margin-top: -30px !important;
}

.treatment-form-subtitles {
  margin-left: 1rem;
}

.treatment-form-titles {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  color: #222222;
  margin-left: 1rem;
}

.treatment-form-heading {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  color: #222222;
  margin-left: 1rem;
  padding-top: 25px;
  margin-top: -5px;
  font-size: 18px;
}

.scan-form-heading{
  padding: 23px 14px 7px 14px;
  h1{
    font-family: "Mulish", sans-serif;
    color: #000;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    margin: 0px;
    text-transform: capitalize;
  }
}

.scan-container {
  // width: 150px;
  font-family: "Mulish", sans-serif;
  padding: 10px;
  // width: 17rem;
}

.scan-subheader {
  text-align: start;
  color: gray;
  // padding-left: 10px;
  margin-bottom: 5px;
}

.scan-subheader-black {
  text-align: start;
  color: black;
  // padding-left: 10px;
  margin-bottom: 5px;
}

.required {
  &:before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}

.scan-uploader {
  border-radius: 10px !important;

  // height: 12rem !important;
  // width: 15rem !important;
  // height: 4rem !important;
  // width: 18rem !important;
  // display: inline-block !important;
  .ant-upload {
    // border-radius: 6px !important;
    // border: 1px solid #E1E1E1 !important;
    // background: #FAFAFA !important;

    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s;
  }
}

.hik-upload {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: rgba(34, 48, 50, 0.72);
    text-align: center;
    font-family: "Mulish", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
  }
}

.scan-upload-text {
  color: rgba(34, 48, 50, 0.72) !important;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 12px !important;
  padding: 0 6px;
  font-weight: 600;
  line-height: normal;
  // display: inline-block !important;
}

.scan-upload-drag-icon span {
  color: gray !important;
  font-size: 1rem !important;
}

// .scan-upload-drag-icon {
//   display: inline-block;
//   width: 10%;
// }


@media only screen and (min-width: 1901px) {
  .scan-form-container .clinicFiles-col .clinic-file-upload .ant-upload-select {
    top: 29px;
    height: calc(66% - 17px);
    width: calc(23% - 5px);

}
}