.fileListContainer {
    margin-top: 1rem;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: 10px;
    overflow-x: scroll;
    width: 100%;
    a {
        max-width: 200px;
        color: #000;
    }
    .resourceName {
        p {
            color: #404040;
            width: 131px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }

    .file {
        cursor: pointer;

        .fileImage {
            position: relative;

            .fileMask {
                position: absolute;
                inset: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                background: rgba(0, 0, 0, 0.5);
           
                opacity: 0;
                transition: opacity 0.3s;
                width: 131px;
                height: 131px;
                border-radius: 8px;

                span {
                    padding-left: 3px;
                }
                .downIcon{
                font-size: 20px;
                margin-right: 1rem;
                cursor: pointer;
                }
                .viewIcon{
                    font-size: 20px;
                    margin-right: 1rem;
                    cursor: pointer;
                }
            }

            &:hover {
                .fileMask {
                    opacity: 1;
                }
            }
        }
    }
}
