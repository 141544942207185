.order-detail-clickable {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.created-from {
  text-transform: capitalize;
  margin-right: 10px;
}
