$secondary-color: #77888b;

.edit-btn-container {
  display: flex;
  justify-content: flex-end;
  &:hover {
    cursor: pointer;
  }

  svg {
    margin-top: 4px;
  }
}

.name {
  color: $secondary-color;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  text-decoration: underline;
}
