.deleteModalButton.ant-btn {
  width: 25%;
  border-radius: 20px;
  font-size: 0.9em;
}

.deleteConfirmBtn.ant-btn,
.deleteConfirmBtn.ant-btn:active,
.deleteConfirmBtn.ant-btn:hover,
.deleteConfirmBtn.ant-btn:focus {
  background-color: #f53b12;
  border: 0px;
}

.deleteCancelBtn.ant-btn,
.deleteCancelBtn.ant-btn:active,
.deleteCancelBtn.ant-btn:hover,
.deleteCancelBtn.ant-btn:focus {
  border-color: #979797;
  color: #979797;
}
