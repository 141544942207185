// @import '~antd/dist/antd.css';
.invoice-table {
  color: #606a6b;
  font-family: Mulish;
}
.ant-table {
  font-size: 15px;
}
.invoice-table .ant-table-thead > tr > th {
  color: #2f3a4c;
  font-weight: 600;
  font-family: Mulish;
  font-size: 14px;
}
.cancel-button {
  border-radius: 44px;
  border: 1px solid #00c6e9;
  color: #00c6e9;
}
.paymentStatus-modal .ant-modal-body {
  max-height: 700px;
}
// .search-bar .ant-btn-icon-only {
//     position: absolute;
//     font-size: 16px;
//     border-radius: none;
//     vertical-align: -1px;
//     margin-left: -20rem;
//     border:none;
//     height:39px;
//   }
// .search-bar .ant-input {
//     border:none;
//     border-radius: none;
//     position: absolute;
//     margin-top: 0.1rem;

// }
