.btn-container {
  margin-top: 0.75rem;
}

.registerInfo{
  .ant-tooltip-inner{
    width: 325px !important;
  }
}

.registration-form-container{
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;


  .register-form{
    width: 80%;
  }
  .registration-form{
    .ant-form-item-label {
      margin-left: 0px !important;
  }
  .ant-col{
    max-width: 100% !important;
    margin: 0 !important;
  }

  
  }
}

.registration-container {
  background-color: white;
  height: 100%;


}

.registration-button {
  width: 100% !important;
  height: 3rem !important;

  border-radius: 91px !important;
  margin-top: 0.5rem;
}

.message-container {
  // margin-top: 15%;
  // padding-left: 10%;
  // padding-right: 10%;
  // padding-bottom: 10%;
  p{
    margin-top: unset;
    margin-bottom: unset;
  }
}

.registration-image-container {
  min-height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0%;
  width: 50vw !important;
}

.image {
  //height: 100vh !important;
  max-height: 1300px;
  object-fit: cover;
  width: 60vw !important;
}

.title-container {
  text-align: left;
  // padding-left: 8%;
  margin-bottom: 2em;
  // margin-top: 10%;
}

@media only screen and (max-width: 1000px) {
  .image {
    display: none;
  }
  .title-container {
    text-align: center;
    padding-left: 0;
  }
}

@media only screen and (max-width: 570px) {
  .btn-container {
    padding-left: 1.4rem;
  }
  .registration-form {
    padding-right: 0.8rem !important;
  }
}

@media only screen and (max-width: 480px) {
  // .title-container {
  //   text-align: left;

  //   padding-left: 4%;
  // }
  .registration-logo {
    margin-left: 0 !important;
  }
  .registration-form {
    padding-right: 0.8rem !important;
  }

  .btn-container {
    width: 100%;
    // margin: 7% auto 0 0;
    padding-left: 0.9rem;
  }
}
