// @import "~antd/dist/antd.css";
.loop {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.treatment-progress-sub-cards {
min-height: 185px;


  .ant-card-head {
    border-bottom: 1px dashed #f0f0f0;
    .header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }
  .select-button {
    width: 4.1rem;
    font-family: Mulish;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
  }
  &:hover {
    .select-button {
      visibility: visible;
    }
  }
}

.treatment-progress-sub-cards-selected {
  border: 2px solid #57c4e5 !important;
  &.treatment-progress-sub-cards-smilealign{
    border: 2px solid #DE8754 !important;
  }
  .select-button {
    display: none;
  }
}

.patient-start-date-title {
  color: #798c96;
  font-weight: 400;
  font-size: 14px;
  // padding:0
}
.patient-end-date-title {
  color: #798c96;
  font-weight: 400;
  font-size: 14px;
  // padding:0
}

.empty-component {
  width: 65%;
  background: #fafafa !important;
  border: 1px dashed #d7d6d6 !important;
  box-sizing: border-box !important;
  border-radius: 13px !important;
  margin-top: -1rem !important;
  padding: 1rem !important;

  //   background-color: red;
}

.aligner-date {
  svg{
    opacity: 0.8;
  }
  &:hover {
    svg {
      // fill: #00c6e9;
      opacity: 1;
      width: 21px;
      height: 21px;
    }
    cursor: pointer;
  
  }
}
