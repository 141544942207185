.clinician-details-container {
    text-align: left;
    padding: 0 1em;
    .ant-form-item{
        margin-bottom: 10px !important;
    }
}

.clinician-details-form {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 20px 20px 0;
    margin-bottom: 20px;
}

.clinician-details-title {
    font-family: 'Poppins';
    font-weight: 400 !important;
    font-size: 1.75em;
    margin-bottom: 28px;
    text-align: start;
}

.clinician-details-title-for-clinic-step {
    // width: 84%;
    margin-top: 0px;
    margin-bottom: 20px;
    // margin-left: 16%;
    font-weight: 600 !important;
}

.clinician-name-form-item {
    position: relative;

    .ant-input {
        &:hover {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }

        &:focus {
            border-color: #d9d9d9 !important;
            box-shadow: none !important;
        }
    }

    &::before {
        content: "*";
        position: absolute;
        left: -15px;
        top: 55%;
        transform: translateY(-50%);
        color: red;
        font-size: 20px;
        z-index: 1;
    }
}

.highlight-text {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.clinician-name-form-item {
    margin-bottom: 8px;
}

.submit-button-container {
    display: flex;
    justify-content: flex-end;
}

.submit-button-without-color {
    &:hover {
        border-color: #EDEDED !important;
        color: #000 !important;
    }
}

// .ant-radio-inner {
//     background-color: transparent;
//     border-color: #00C6E9 !important;
// }

// .ant-radio-checked .ant-radio-inner {
//     background-color: #00C6E9 !important;
//     border-color: #00C6E9 !important;
// }

// .ant-radio-wrapper:hover .ant-radio-inner {
//     border-color: #00C6E9 !important;
// }

.react-international-phone-input-container {
    gap: 10px;

    .react-international-phone-input {
        width: 100%;
    }

}



.react-international-phone-input-container {
    .react-international-phone-input {
        height: 40px;
        border-radius: 0;
    }

    .react-international-phone-country-selector-button {
        height: 40px;
        width: 70px;
        border-radius: 0;

        .react-international-phone-country-selector-button__button-content {
            gap: 10px;

        }
    }
}