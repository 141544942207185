$alignerColor-1: #00C6E9;
$alignerColor-2: #F2D076;
$alignerColor-3: #334A50;
$alignerColor-4: #D6DBD7;
$alignerColor-5: #DA786B;
$alignerColor-6: #B3B689;

$alignerColor-7: #7A8B67;
$alignerColor-8: #FFD8C2;
$alignerColor-9: #D98E6F;
$alignerColor-10: #647F6E;

$alignerColor-11: #F4B69D;
$alignerColor-12: #B8CECC;
$alignerColor-13: #3A615E;
$alignerColor-14: #9F5E64;
$alignerColor-15: #C58557;

$alignerColor-16: #EACFC4;
$alignerColor-17: #C48849;
$alignerColor-18: #cfb7ad;
$alignerColor-19: #DB7858;

$alignerColor-20: #F1AE79;
$alignerColor-21: #7C5E60;
$alignerColor-22: #F4E2CE;
$alignerColor-23: #B2B7A0;
$alignerColor-24: #F4E2CE;
$alignerColor-25: #E9C0AA;

$alignerColor-26: #7574A8;
$alignerColor-27: #6988C5;
$alignerColor-28: #69C5C5;
$alignerColor-29: #A2C569;
$alignerColor-30: #C5697F;



.ipr-circle-1 {
  background: $alignerColor-1;
}

.ipr-circle-2 {
  background: $alignerColor-2;
}

.ipr-circle-3 {
  background: $alignerColor-3;
}

.ipr-circle-4 {
  background: $alignerColor-4;
}

.ipr-circle-5 {
  background: $alignerColor-5;
}

.ipr-circle-6 {
  background: $alignerColor-6;
}

.ipr-circle-7 {
  background: $alignerColor-7;
}

.ipr-circle-8 {
  background: $alignerColor-8;
}

.ipr-circle-9 {
  background: $alignerColor-9;
}

.ipr-circle-10 {
  background: $alignerColor-10;
}

.ipr-circle-11 {
  background: $alignerColor-11;
}

.ipr-circle-12 {
  background: $alignerColor-12;
}

.ipr-circle-13 {
  background: $alignerColor-13;
}

.ipr-circle-14 {
  background: $alignerColor-14;
}

.ipr-circle-15 {
  background: $alignerColor-15;
}

.ipr-circle-16 {
  background: $alignerColor-16;
}

.ipr-circle-17 {
  background: $alignerColor-17;
}

.ipr-circle-18 {
  background: $alignerColor-18;
}

.ipr-circle-19 {
  background: $alignerColor-19;
}

.ipr-circle-20 {
  background: $alignerColor-20;
}

.ipr-circle-21 {
  background: $alignerColor-21;
}

.ipr-circle-22 {
  background: $alignerColor-22;
}

.ipr-circle-23 {
  background: $alignerColor-23;
}

.ipr-circle-9 {
  background: $alignerColor-9;
}

.ipr-circle-24 {
  background: $alignerColor-24;
}

.ipr-circle-25 {
  background: $alignerColor-25;
}

.ipr-circle-26 {
  background: $alignerColor-26;
}

.ipr-circle-27 {
  background: $alignerColor-27;
}

.ipr-circle-28 {
  background: $alignerColor-28;
}

.ipr-circle-29 {
  background: $alignerColor-29;
}

.ipr-circle-30 {
  background: $alignerColor-30;
}


.ipr-session-card {
  border-radius: 4px;
  border: 1.5px solid #ECECEC;
  // background: #EBF9FF;
  background: #F9F9F9;
  text-align: left;
  .ant-card-body{
    padding: 20px 15px 20px 20px ;
  }
  

  h1 {
    color: #343434;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
  }

  h2 {
    color: #343434;
    font-family: "Mulish", sans-serif;
    font-size: 14px;
    font-weight: 700;
  }

  .session-icon {
    display: flex;
    justify-content: center;
    margin-left: auto;
    width: 90%;
  }

  .ant-checkbox {
    span {
      color: #343434;
      font-family: "Mulish", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

}


.treatment-collapse {
  .ant-collapse-content-box {
    padding: 0px !important;
  }
}

.aligner-component {
  width: 100%;
  background: #fafafa;
  border: 1px dashed #d7d6d6;
  box-sizing: border-box !important;
  border-radius: 13px !important;
  overflow: auto;
  // max-height: 13rem !important;
  padding: 15px 10px;
  margin-top: 15px;
  
  .download-btn{
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;
    svg{
     margin-left: 4px;
    }
  }

  .heading-radio{
    display: flex !important;
    .ant-radio-button-wrapper{
      min-width: 150px !important;
      height: 40px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }

  }

  .attachmentInfo-container {
    padding-top: 10px;
    max-height: 430px;
    overflow-y: scroll;
    overflow-x: hidden;

    .toothName {
      border-radius: 6px;
      color: #FFF;
      font-size: 16px;
      font-weight: 700;
      width: 100%;
      text-align: center;
      padding: 5px;
      margin: 0;
    }

    .attachment-response {
      border-radius: 5px;
      background: #FFF;
      text-align: justify;
      padding: 10px 5px;
      color: #000;
      font-family: "Mulish", sans-serif;
      font-size: 15px;
      font-weight: 400;
      min-height: 37.5px;
      word-break: break-all;
    }
  }


  .ipr-file-container {
    .ant-upload {
      display: none !important;
    }

    .ant-upload-list-picture-card {
      padding: 0px !important;
    }
  }

  .ant-collapse-header {
    padding: 10px 0px !important;
  }

  .ant-collapse-content-box {
    padding: 0px !important;
  }

  .ant-collapse-expand-icon {
    height: 40px !important;
  }



  .chart-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-form-item {
      margin-bottom: 0px !important;
    }

    h1 {
      margin: 0;
      color: #000;
      font-family: Mulish;
      font-size: 15px;
      font-weight: 700;
    }

    .header-select {
      width: 154px;
      text-align: left;
    }

  }

  .ipr-checks-scroll{
    height: 300px;
    overflow: scroll;
    margin-bottom: 1rem;
    
  }
  
  .aligner-scroll-container{
    height: 300px;
    overflow: scroll;
    padding-top: 25px;
  }

  .ipr-check-row {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;

    // gap: 10px;
    .ant-checkbox-wrapper {
      //   align-self: flex-start !important;
      justify-self: center !important;
    }

    .patient-question {
      min-width: 250px;
      text-align: left;
    }

    .ipr-circle {
      align-self: center;
      // align-self: flex-end !important;
      // justify-self: flex-end !important;
      border-radius: 7px;
      // background: #000;
      width: 14px;
      height: 14px;
    }

  }

  .ipr-detail-container {
    background: #fff;
    padding-top: 15px;
    border-radius: 8px;

    .aligners-container {

      .aligner-rows {
        display: flex;
        align-items: center;

        .aligner-info {
          display: flex;
          align-items: center;
          position: absolute;
          top: 4px;
          left: 0px;

          h2 {
            color: #000;
            font-family: "Mulish", sans-serif;
            font-size: 12px;
            font-weight: 600;
            margin: 0;
          }

          p {
            color: #000;
            font-family: "Mulish", sans-serif;
            font-size: 10px;
            font-weight: 600;
            margin: 0;
          }

          .aligner-num {
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            width: 30px;
            position: relative;
            color: #fff;
            z-index: 15;
            font-weight: 700;
          }
        }
      }

      .aligner-row {
        align-items: flex-end;
        display: grid;
        grid-template-columns: repeat(15, 1fr);
        column-gap: 1px;
        row-gap: 0px;
        position: relative;
        width: 92%;
        margin: 0px auto 0px auto;

      }

      .aligner-circle {
        width: 30px;
        height: 28px;
        line-height: 25px;
        border-radius: 50%;
        text-align: center;
        font-weight: 600;
        color: #fff;
        align-self: center;
        justify-self: center;
        margin-bottom: 10px;
        position: relative;

        // &::after {
        //   content: '';
        //   background: #777777;
        //   height: 20px;
        //   width: 1px;
        //   position: absolute;
        //   z-index: 12;
        //   left: 50%;
        // }
      }

      .aligner-rows {
        position: relative;
        cursor: pointer;
        width: 100%;
        border-radius: 8px;
        padding: 5px 0px;

        .del-btn {
          position: absolute;
          top: 5px;
          right: -40px;
          border: none;
          box-shadow: none;
          background: transparent;
          z-index: 2;
        }
      }

      .selected-aligner {
        background: #e1f0f3;

      }
    }

    .aligner-0-upper {
      position: relative;

      &::before {
        content: '';
        background-image: url('../../../images/IprArrow.png');
        position: absolute;
        bottom: -29px;
        z-index: 12;
        width: 18px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        left: 25%;
      }
    }

    .aligner-0-lower {
      position: relative;

      &::before {
        content: '';
        background-image: url('../../../images/IprArrow.png');
        transform: rotate(180deg);
        position: absolute;
        top: -30px;
        z-index: 12;
        /* left: -6px; */
        width: 24px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        right: 30%;
      }
    }

    .aligner-upper {
      &::after {
        top: -20px;
      }
    }

    .aligner-lower {
      &::after {
        bottom: -20px;
      }
    }





    .aligner-1 {
      border: 1px solid $alignerColor-1;
    }

    .aligner-2 {
      border: 1px solid $alignerColor-2;
    }

    .aligner-3 {
      border: 1px solid $alignerColor-3;
    }

    .aligner-4 {
      border: 1px solid $alignerColor-4;
    }

    .aligner-5 {
      border: 1px solid $alignerColor-5;
    }

    .aligner-6 {
      border: 1px solid $alignerColor-6;
    }

    .aligner-7 {
      border: 1px solid $alignerColor-7;
    }

    .aligner-8 {
      border: 1px solid $alignerColor-8;
    }

    .aligner-9 {
      border: 1px solid $alignerColor-9;
    }

    .aligner-10 {
      border: 1px solid $alignerColor-10;
    }

    .aligner-11 {
      border: 1px solid $alignerColor-11;
    }

    .aligner-12 {
      border: 1px solid $alignerColor-12;
    }

    .aligner-13 {
      border: 1px solid $alignerColor-13;
    }

    .aligner-14 {
      border: 1px solid $alignerColor-14;
    }

    .aligner-15 {
      border: 1px solid $alignerColor-15;
    }

    .aligner-16 {
      border: 1px solid $alignerColor-16;
    }

    .aligner-17 {
      border: 1px solid $alignerColor-17;
    }

    .aligner-18 {
      border: 1px solid $alignerColor-18;
    }

    .aligner-19 {
      border: 1px solid $alignerColor-19;
    }

    .aligner-20 {
      border: 1px solid $alignerColor-20;
    }

    .aligner-21 {
      border: 1px solid $alignerColor-21;
    }

    .aligner-22 {
      border: 1px solid $alignerColor-22;
    }

    .aligner-23 {
      border: 1px solid $alignerColor-23;
    }

    .aligner-24 {
      border: 1px solid $alignerColor-24;
    }

    .aligner-25 {
      border: 1px solid $alignerColor-25;
    }

    .aligner-26 {
      border: 1px solid $alignerColor-26;
    }

    .aligner-27 {
      border: 1px solid $alignerColor-27;
    }

    .aligner-28 {
      border: 1px solid $alignerColor-28;
    }

    .aligner-29 {
      border: 1px solid $alignerColor-29;
    }

    .aligner-30 {
      border: 1px solid $alignerColor-30;
    }

    .ipr-row {
      .plus {
        width: 21px;

        svg {
          width: 21px;
        }

        position: absolute;
        top: 40%;
        right: -15%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        color: #000;
        font-size: 12px;
        font-weight: 700;
        z-index: 1;
      }
    }
  }

  .treatment-collapse {
    background: #fff !important;
  }

}

.patient-question {
  color: #798c96;
  font-weight: 400;
  font-size: 14px;
  font-family: "Mulish", sans-serif;
}

.patient-response {
  font-weight: 800;
  font-size: 14px;
  color: #000;
  font-family: "Mulish", sans-serif;
  text-align: left;
}

.detail-container{
  font-weight: 400;
  text-align: justify;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #cccc;
  padding: 5px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 7px;
}

.empty-plan-component {
  width: 65%;
  background: #fafafa !important;
  border: 1px dashed #d7d6d6 !important;
  box-sizing: border-box !important;
  border-radius: 13px !important;
  margin: 2rem !important;
  padding: 1rem !important;
}

.ipr-modal-container {
  .header-select {
    width: 154px !important;
    margin-bottom: 10px;
  }
  .detail-container{
    font-weight: 400;
    text-align: justify;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #cccc;
    padding: 5px;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 7px;
  }

  .ipr-file-container {
    .ant-upload {
      display: none !important;
    }

    .ant-upload-list-picture-card {
      padding: 0px !important;
    }
  }

  .teethChart-container .teethRow-container .teethRow-one svg {
    width: 47px !important;
  }

  .aligner-component .ipr-detail-container .aligners-container .aligner-circle {
    width: 30px !important;
    height: 28px !important;
    line-height: 25px;
  }

  .teethChart-container .teethRow-container .teethRow-bgTwo {
    padding: 0px !important;
  }

  .teethChart-container .teethRow-container .teethRow-bgOne {
    padding: 0px !important;
  }

  // .aligner-component .ipr-detail-container .aligners-container .aligner-row {
  //   width: 92% !important;
  // }

  .aligner-component .ipr-detail-container {
    padding-top: 0px;
  }

  .aligner-0-upper {
    position: relative;

    &::before {
      left: 6px !important;
    }
  }

  .aligner-0-lower {
    position: relative;

    &::before {
      left: -2px !important;
    }
  }
}


@media only screen and (min-width: 1000px) and (max-width: 1300px) {
  // .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
  //   height: 24px !important;
  // width: 24px !important;
  // }
  .ipr-detail-container {
    .teethChart-container .teethRow-container .teethRow-one svg {
      width: 29px;
    }
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1400px) {
  // .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
  //   height: 24px !important;
  // width: 24px !important;
  // }
  .ipr-detail-container {
    .teethChart-container .teethRow-container .teethRow-one svg {
      width: 30px;
    }
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1500px) {
  // .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
  //   height: 25px !important;
  // width: 25px !important;
  // }
  .ipr-detail-container {
    .teethChart-container .teethRow-container .teethRow-one svg {
      width: 33px;
    }
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1600px) {
  // .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
  //   height: 28px !important;
  // width: 28px !important;
  // }
  .ipr-detail-container {
    .teethChart-container .teethRow-container .teethRow-one svg {
      width: 36px ;
    }
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1700px) {
  // .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
  //   height: 28px !important;
  // width: 28px !important;
  // }
  .ipr-detail-container {
    .teethChart-container .teethRow-container .teethRow-one svg {
      width: 40px ;
    }
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  // .ipr-detail-container .aligners-container .aligner-rows .aligner-info .aligner-num {
  //   height: 30px !important;
  // width: 30px !important;
  // }
  .ipr-detail-container {
    .teethChart-container .teethRow-container .teethRow-one svg {
      width: 43px;
    }
  }
}

@media only screen and (min-width: 1901px) {
  .ipr-detail-container {
    .teethChart-container .teethRow-container .teethRow-one svg {
      width: 48px;
    }
  }
}

// @media only screen and (min-width: 1601px) {
//   .ipr-detail-container {
//     .teethChart-container .teethRow-container .teethRow-one svg {
//       width: 49px;
//     }
//   }
// }


// @media only screen and (min-width: 1400px) and (max-width: 1600px) {
//   .ipr-detail-container {
//     .teethChart-container .teethRow-container .teethRow-one svg {
//       width: 34px;
//     }
//   }

//   .aligner-component .ipr-detail-container .aligners-container .aligner-circle {
//     width: 25px;
//     height: 23px;
//     font-size: 11px;
//     line-height: 22px;
//   }

// }
