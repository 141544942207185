.availability-container {
    padding: 30px;
    text-align: left;

    .ant-btn {
        // background: transparent !important;
        // border: none !important;
    }

    h1 {
        color: #000;
        font-family: "Poppins", "Mulish";
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
    }


    .book-appointment {
        border-radius: 4px;
        background: #E8FBFF;
        padding: 14px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        gap: 1.5rem;
        margin-right: -30px;

        label {
            color: #000;
            font-size: 16px;
            font-weight: 400;
        }

        span {
            color: #000;
            font-size: 16px;
            font-weight: 700;
        }
    }


    .ant-select {
        text-align: left;
    }

    .avail-form-container {
        // margin: 0 40px;
    }

    .timings-row-container {
        display: flex;
        // align-items: center;
        gap: 8px;

        // flex-direction: column;
        position: relative;
        justify-content: space-between;


        .timings-row {
            // position: relative;
            // display: flex;
            width: 100%;
            position: relative;
            margin-bottom: 10px;
            // padding-right: 35px;

            .ant-form-item {
                width: 100%;
                display: block;
            }

            .remove-btn {
                position: absolute;
                top: 7px;
                right: -35px;

            }

        }

        .buttons-row {
            display: flex;
            // align-items: center;
            gap: 10px;
            width: 50px;
            margin-top: 5px;
            justify-content: space-between;

            .ant-btn {
                width: 20px;
            }

            span {
                width: 20px;
            }

            svg {
                width: 20px;
            }
        }

        // .buttons-row {
        //     display: flex;
        //     align-items: center;
        //     gap: 10px;
        //     width: 50px;

        //     // gap: 20px;
        //     // position: absolute;
        //     // top: 5px;
        //     // right: -22px;

        // }
    }

    .breakHours-row {
        display: flex;
        align-items: center;
        width: 100%;

        .ant-form-item {
            width: 100%;
            display: block;
        }

        .break-row {
            margin-left: 8px;

            .ant-btn {
                width: 20px;
            }

            span {
                width: 20px;
            }

            svg {
                width: 20px;
            }
        }
    }

    .ant-picker {
        border: 1px solid #d9d9d9 !important;
        height: 41px !important;
        // width: 100% !important;
        box-sizing: border-box;

    }

    .ant-form-item {
        margin-bottom: 0px !important;
    }

    .title {
        font-family: 'Poppins', sans-serif;
        text-align: left;
    }

    .form-title {
        color: #000;
        font-size: 14px;
        font-weight: 500;
    }

    .first-title {
        text-align: right;
    }

    .avail-col-one {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: flex-end;

        .ant-input {
            background: transparent;
            border: none;
            color: #798c96;
        }

        .day-name {
            min-width: 120px;
        }
    }

    .avail-add-btn {
        border: none;
        outline: none;
        box-shadow: none;
        width: 15px;
        background: transparent;
    }
}