.modalButton.ant-btn {
  width: 25%;
  border-radius: 20px;
  font-size: 0.9em;
}

.cancelBtn.ant-btn {
  // border-color: #00c6e9;
  // color: #00c6e9;
}

.request-partner{
  width: unset !important;
}