.profile-view-container {
    margin: 20px 20px;

    .heading {
        color: #000;
        font-family: "Poppins", "Mulish";
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
    }

    .info-col {
        margin-top: 15px;

        .profile-form-label label {
            font-family: 'Mulish';
            font-weight: 700;
            font-size: 16px;
            color: #000 !important;
        }
    }

    .form-info {
        font-size: 'Mulish';
        margin-top: 25px;
        text-align: left;
        padding-left: 20px;

        .form-info-label {
            font-size: 'Mulish';
            font-size: 14px;
            color: #000;
            font-weight: 700;
        }

        .form-info-value {
            font-size: 16px;
            font-weight: 500;
        }

        .form-info-disabled {
            color: #7C7C7C;
            font-size: 16px;
            font-weight: 600;
        }

    }

    .form-user-icon {
        svg {
            width: 13rem;
            height: 13rem;
        }
    }

    .form-user-phone {
        svg {
            width: 18rem;
            height: 18rem;
        }
    }

    .phone-input {
        input {
            margin-left: 14px;
            width: calc(100% - 14px);
        }
    }

    :global(.ant-input-group-addon) {
        border: 1px solid #d9d9d9 !important;
        border-radius: 4px !important;
    }


}
