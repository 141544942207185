.custom-slots {
  .ant-radio-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 8px;
  }
  // label{
  //     margin-right: 1rem;
  //     margin-bottom: 1rem;

  // }
  .ant-radio-button-wrapper {
    border: 1px solid lightgrey;
    border-radius: 6px !important;
    text-align: center;
    padding: 0;
    font-size: 13px;
    width: 85px;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
}

.calendar-wrapper {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
}

.patient-disabled-results {
  input {
    height: 28px;
    margin-bottom: 0.8rem;
  }
}

.appointment-header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  h2{
    margin: 0px;
  }
}

.slots-skeleton-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
}
