.step-layout {
  // min-height: 700px; // Full height for the main container
  width: 100%; // Full width for the main container
  background-color: #fff; // You can adjust the background color as needed
  // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px;

  .progress-bar {
    .ant-progress-outer {
      position: absolute;
      left: 0;
      top: -18px;

      .ant-progress-inner {
        border-radius: 0px;

        .ant-progress-bg {
          border-radius: 0px !important;
        }
      }
    }
  }

  .right-corner-img {
    max-width: 150px;
    position: relative;
    top: 25px;
    right: 25px;
    z-index: 100;
  }


}