.upload-file-container {
    background-color: white;
  border-radius: 6px;
  padding-bottom: 2rem;
  .ant-progress-line{
    margin-bottom: 2px;
  }
  .custom-file-list-container{
    margin: 0 2rem 0.5rem 2rem;
    .custom-file-list{
      position: relative;
      height: 66px;
      padding: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .filename {
        margin-right: 8px; 
      }
      
      .progress {
        flex: 1; 
        margin-right: 8px; 
      }
      .list-file-name{
        color: #1677ff;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    text-transform: capitalize;
    padding: 0 8px;
      }
      .delete-btn{
        flex: 0;

        margin: 0 10px 0 30px;
        border: none;
        svg{
          path{
            fill: black;
          }
        }
        &:hover{
          svg{
            path{
              fill: red;
            }
          }
        }
      }
    }
  }

  .ant-upload-list{
  margin-top: 10px;
  }
  .ant-upload-list-item-thumbnail{
    display: none;
  }
  .ant-upload-list-item-name{
    text-align: left;
    text-transform: capitalize;
  }
  .ant-input-group-addon {
    background-color: white !important;
    border-left-style: none !important;
  }
  .ant-input {
    border-right-style: none !important;
    //border-right-style: none;
  }
  //.add-link-input {
  //  //border-right-style: unset !important;
  //  .ant-input {
  //    //border-right-style: none !important;
  //    border-right-style: unset !important;
  //    border: 1px solid  !important;
  //  }
  //}
  .ant-upload {
    padding:0px !important;
  }
  .ant-upload.ant-upload-drag {
    border: 1px solid #d9d9d9;
  }
  .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item{
    height: 100px !important;
  }
  .ant-upload-list-item {
    margin: 0 2rem 0.5rem 2rem !important;
  }

  .ant-upload-list-item {
    clear: both !important
  }
  margin-top: -15px;
  .drag-upload-custom{
    position: relative;
    
    //  top: -80px;
    .ant-upload {
     height: 40px;
    }
  }
}
.upload-content{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
}

.upload-files-title {
  margin: 1.5rem 0.5rem 0 2rem !important;

  font-family: "Poppins", sans-serif !important;
}


.dragger-title {
  margin: 14px 0.5rem 14px 2rem !important;
  font-weight: 500;
  text-align: left;
  font-weight: bold;
}
.file-support{
        font-size: 12px;
        color: rgba(127,120,120,0.72);
        font-weight: 500;
        text-align: left;
        margin: 10px 0px;

}
.input-length{
  text-align: left;
  font-size: 12px;
  margin-left: 2rem;
  color: rgba(127, 120, 120, 0.72);
  font-weight: 500;
  margin-top: 0px;
}

.add-title-input{
   width: 100%;
  // float: "right",
  margin: 0.5rem 2rem,
}
.add-link-input{
  display: inline-block;
  padding-right: 30px;
  z-index: 2;
}

.url-title {
  // margin: 1.5rem 22.3rem 0 0 !important;
  text-align: left;
  // background-color: blue !important;
  font-weight: 600;
}
.file-container{
  height: 100%;
}
