.appointment-scheduling-heading {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  // margin-left: 2rem;
}

.status-radio-buttons {
  width: 8rem !important;
  height: 90% !important;
  padding-top: 0.1rem !important;
}
