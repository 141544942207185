.profile-view-container {
    margin: 20px 20px;

    .heading {
        color: #000;
        font-family: "Poppins", "Mulish";
        font-size: 20px;
        font-weight: 500;
        margin: 0px;
    }

    .info-col {
        margin-top: 15px;
    }

    .form-info {
        font-size: 'Mulish';
        margin-top: 25px;
        text-align: left;
        padding-left: 20px;

   
        .form-info-disabled {
            font-size: 'Mulish';
            color: #7C7C7C;
            font-size: 16px;
            font-weight: 600;
        }

    }

    .form-user-icon {
        svg {
            width: 10rem;
            height: 10rem;
        }
    }

    .form-user-phone {
        svg {
            width: 15rem;
            height: 15rem;
        }
    }

    @media screen and (max-width: 1000px) {
        .form-user-phone {
            svg {
           width: 100%;
            }
        }
        .form-user-icon {
            svg {
                width: 100%;
            }
        }
      }

}


