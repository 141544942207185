.slot-outer-container {
  margin: 0.06rem 1rem 3rem 1rem;

  width: 99%;
}
.slots-container {
  border-radius: 12px;

  width: 27rem;
  background: blue !important;
}

.slot-buttons {
  margin: 0.5rem 0 0.5rem 0.5rem !important;
  border-radius: 7px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: lightgray !important;
  height: 3rem !important;
  width: 7.5rem !important;
  font-size: 13px !important;
  font-weight: 550 !important;
  color: rgba(119, 136, 139, 0.85) !important;
  // background-color: red !important;

  padding: 0.8rem !important;
}

.radio-buttons-container {
  // display: flex !important;
  // flex-wrap: wrap;
   display: grid;
   grid-template-columns: repeat(7,1fr);
   gap: 10px;
   background: red !important;
  margin-top: 1rem;
  margin-bottom: 1rem !important;
  margin-left: 1.4rem;
  margin-right: 1.4rem;


  .ant-radio-wrapper-checked {
    // background-color: #3dcee8;
    // border-color: #3dcee8 !important;
    // color: white !important;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
  }

  .ant-radio-wrapper:hover {
    // border-color: #3dcee8 !important;
    // color: #3dcee8;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
  }
}

.slots-title-container {
  border-bottom-style: solid;
  border-width: 1px;
  border-color: rgba(161, 182, 218, 0.47);
}

.slots-title {
  margin: 1.5rem 0.5rem 1.5rem 2rem !important;

  font-family: 'Poppins', sans-serif !important;
}

@media only screen and (max-width: 992px) {
  .slots-container {
    margin-top: 1.5rem;
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .slots-title {
    margin: 1.5rem 1rem 1.5rem 1rem !important;
  }
  .radio-buttons-container {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
