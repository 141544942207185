@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap");


.notification-container{
  margin-left: 150px;

}

.notifications-container {
  background-color: white;
  width: 80%;
  overflow-y: auto !important;
  text-align: left;
}
.notifications-text {
  margin-left: 50px;
  text-align: left;
  width: 70%;
  margin-top: -15px;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
}
.notifications-text-read {
  margin-left: 50px;
  text-align: left;
  width: 70%;
  margin-top: -15px;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: #a7acad;
}
.notifications-timestamp {
  width: 10%;
  margin-top: -25px;
  float: right;
}

.notifications-timestamp-read {
  width: 10%;
  margin-top: -25px;
  float: right;
  color: #a7acad;
}

.notifications-status {
  background-color: #38c976;
  height: 10px;
  width: 10px;
  border-radius: 15px;
}

.notifications-status-read {
  background-color: #bbbbbb;
  height: 10px;
  width: 10px;
  border-radius: 15px;
}

.button-notifications {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 100%;
  &:hover{
    cursor: pointer;
  }
}

@media screen and (max-width: 1020px) {
  .notification-container{
    margin: 20px;
  }
  .notifications-container {
    width: 100%
  }
}