.form-label {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin: 0px 0px 8px 0px;
    position: relative;
}
.required-input {
    &::before {
        border-radius: 50%;
        display: flex;
        content: "*";
        color: red;
        position: absolute;
        left: -7px;
        top: 2px;
        font-size: 16px;
    }
  }