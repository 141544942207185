@import '../variables.scss';

.appointment-card {
  width: 100%;
  background: #fafafa !important;
  border: 1px dashed #d7d6d6 !important;
  box-sizing: border-box !important;
  border-radius: 13px !important;
  //   background-color: red;
}

.appointment-card-title {
  //   margin-left: 1rem;
}

.card-headings {
  margin:0;
  white-space: nowrap;
  overflow: hidden;
  width:250px;
  text-overflow: ellipsis
}

.card-sub-headings {
  color: #77888b;
}

.card-sub-heading-children {
  font-weight: 750;
  margin-top: -2px;
}

.card-icon {
  color: $primaryColor;
  font-size: large !important;
  margin-bottom: 0.5rem !important;
}

.change-clinician {
  color: #00c6e9 !important;
  font-size: 0.85rem !important;
  cursor: pointer !important;
}
